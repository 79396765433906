import React, {
  useState,
  createRef,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import axios from "axios";
import {
  AppBar,
  CardTitle,
  AppBarSection,
  AppBarSpacer,
  Card,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { MyDatePicker } from "./MyDatePicker";
import "../src/RoomBooking.scss";
import "../src/Common.scss";
import "./schedular.scss";
import {
  DayView,
  Scheduler,
  SchedulerSlot,
  SchedulerDataChangeEvent,
  MonthView,
} from "@progress/kendo-react-scheduler";
import { FormWithCustomEditor } from "./hourlycalender/custom_form";
import { Slider } from "@alm-engineering/react-scroll-snap-slider";
import {
  checkCircleIcon,
  eyeIcon,
  eyeSlashIcon,
  xIcon,
} from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import {
  SchedulerEditItem,
  SchedulerEditItemProps,
} from "@progress/kendo-react-scheduler";
import { apiAuthenticateLogin } from "./services/apis/LoginApi";
import {
  encryptedBase64Password,
  encryptedBcryptPassword,
  commonBaseUrl,
  commonImageUrl,
  razorPayKey,
  razorPaySecret,
} from "./services/CommonUtils";
import { Popup } from "@progress/kendo-react-popup";
import { useNavigate } from "react-router-dom";
import usePreventBackNavigation from "./services/usePreventBackNavigation";
import SessionTimeout from "./services/SessionTimeout";

const slidesPerPageSettings = {
  mobileSmall: 1.5,
  mobileBig: 2.5,
  tablet: 4,
  desktop: 5,
};

interface SlotInfo {
  total: number;
  booked: number;
  available: number;
  bookedFlag: string;
  slotDate: string;
}

interface FormErrors {
  spaceDateErr?: string;
  spaceDurationErr?: string;
  companyNameErr?: string;
  customerNameErr?: string;
  customerMobileErrr?: string;
  customerEmailErrr?: string;
  customerAddresEerrr?: string;
  customerKycNumberErrr?: string;
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  bookingFromErrr?: string;
  bookingToErrr?: string;
}

interface RegisterFormErrors {
  companyNameErr?: string;
  customerNameErr?: string;
  customerMobileErrr?: string;
  customerEmailErrr?: string;
  customerAddres1Eerrr?: string;
  customerAddres2Eerrr?: string;
  customerCountryErrr?: string;
  customerStateErrr?: string;
  customerCityErrr?: string;
  customerPincodeErrr?: string;
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  customerKycNumberErrr?: string;
  customerPasswordErrr?: string;
  customerConfirmPasswordErrr?: string;
}

interface ErrorRequired {
  companyNameErr?: boolean;
  customerNameErr?: boolean;
  customerMobileErrr?: boolean;
  customerEmailErrr?: boolean;
  customerAddres1Eerrr?: boolean;
  customerAddres2Eerrr?: boolean;
  customerCountryErrr?: boolean;
  customerStateErrr?: boolean;
  customerCityErrr?: boolean;
  customerKycNumberErrr?: boolean;
  customerPincodeErrr?: boolean;
  customerKYCDocumentErrr?: boolean;
}

interface ErrorRegisterRequired {
  companyNameErr?: boolean;
  customerNameErr?: boolean;
  customerMobileErrr?: boolean;
  customerEmailErrr?: boolean;
  customerAddres1Eerrr?: boolean;
  customerAddres2Eerrr?: boolean;
  customerKYCDocumentErrr?: boolean;
  customerCountryErrr?: boolean;
  customerStateErrr?: boolean;
  customerCityErrr?: boolean;
  customerKycNumberErrr?: boolean;
  customerPincodeErrr?: boolean;
  customerPassowordErrr?: boolean;
  customerConfirmPasswordErrr?: boolean;
}

// Login Interface
interface LoginFormErrors {
  userNameErr?: string;
  userPasswordErrr?: string;
}

interface ErrorLoginRequired {
  userNameErr?: boolean;
  userPasswordErrr?: boolean;
}

// New structure
export interface rooms {
  Id: number;
  SpaceName: string;
  NoOfSeats: number;
  GapBetweenNetBooking: boolean;
  GapTiming: number;
  OverBookingAllowed: boolean;
  OverBookingAllowedPercentage: number;
  SASCCode: string;
  Description: string;
  IsActive: boolean;
  IsDelete: boolean;
  SpaceOpenTime: string;
  SpaceCloseTime: string;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: string;
  ModifiedOn: string;
  SpaceImage: string;
  SpaceDetails: SpaceDetails[];
}

export interface SpaceDetails {
  Id: number;
  SpaceHeaderId: number;
  SpaceOpenTime: string;
  SpaceCloseTime: string;
  DurationId: number;
  PackagaDetailsId: number;
  CancellationPolicyDetails: string;
  DepositAmount: number;
  RentAmount: number;
  TaxPercentage: number;
  IsActive: boolean;
  IsDelete: boolean;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
  Duration: {
    Id: number;
    DurationType: string;
    DurationTypeId: number;
    Description: string;
    HasVarient: number;
    Varient1: string;
    Varient2: string;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
    VarinetId: null;
  };
  Tax: {
    Id: number;
    Name: string;
    Description: string;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: string;
  };
  PackageDetails: {
    Id: number;
    PackageName: string;
    Inclusion: string;
    Exclusion: string;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedBy: number;
    CreatedOn: string;
    ModifiedBy: number;
    ModifiedOn: number;
  };
}

export interface SpaceImageData {
  Id: number;
  Name: string;
  Type: string;
  RefNo: number;
  RefType: string;
  Document: string;
  ImagePath: string;
  SubType: string;
  IsActive: boolean;
  IsDelete: boolean;
  CreatedBy: number;
  CreatedOn: string;
  ModifiedBy: number;
  ModifiedOn: string;
}

export interface DurationDetails {
  Id: number;
  SpaceHeaderId: number;
  DurationId: number;
  PackagaDetailsId: number;
  CancellationPolicyDetails: string;
  DepositAmount: number;
  RentAmount: number;
  TaxId: number;
  TaxPercentage: number;
  IsActive: boolean;
  DurationName: string;
  DurationTypeId: number;
  HasVarient: number;
  VarientName: string;
  VarientNumber: number;
  VariantId: number;
  DurationIsActive: boolean;
  durationTypeName: string;
  DurationTypeIsActive: boolean;
  inclusionPackage: string;
  exclusionPackage: string;
  packageName: string;
}

export const BookingForm = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  usePreventBackNavigation();

  // Defaut values
  const defaultSeatCountValue = "1";
  const defaultMonthCountValue = "1";
  const defaultDurationValue = { DurationName: "Select Duration" };
  const defaultCountryValue = { countryName: "Select Country" };
  const defaultStateValue = { stateName: "Select State" };
  const defaultCityValue = { cityName: "Select City" };
  const defaultKYCDicumentValue = { Name: "Select KYC Document" };
  const defaultFromTimeValue = "Select From Time";
  const defaultToTimeValue = "Select To Time";
  const mobileRegex = /^[6-9][0-9]{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const redColor = "#FDDAD6";
  const greenColor = "#BBFFBC";
  const yellowColor = "#FEF7B5";
  const whiteColor = "#FFFFFF";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const navigate = useNavigate();

  // Top filters
  const [checkInDate, setCheckInDate] = useState<Date | null>(null);
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(null);
  const [selectedFromTime, setSelectedFromTime] =
    useState(defaultFromTimeValue);
  const [selectedToTime, setSelectedToTime] = useState(defaultToTimeValue);
  const [selectedDuration, setSelectedDuration] =
    useState(defaultDurationValue);
  const [selectedDurationTypeID, setSelectedDurationTypeID] =
    useState<string>();
  const [selectedDurationID, setSelectedDurationID] = useState<string>();
  const [selectedDurationName, setSelectedDurationName] = useState<string>();
  const [selectedDurationTypeName, setSelectedDurationTypeName] =
    useState<string>();
  const [selectedKYC, setSelectedKYC] = useState(defaultKYCDicumentValue);
  const [selectedKYCID, setSelectedKYCID] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [selectedKYCName, setSelectedKYCName] = useState<string>("");
  const [orginalTime, setOrginalTime] = useState(8);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false); //screen 3
  const [mobileError, setMobileError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordValidationError, setPasswordValidationError] = useState("");

  // validate password
  const MIN_LENGTH = 8;
  const MAX_LENGTH = 20;
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasNumber = /\d/;
  const hasSymbol = /[!@#$%^&*_]/;
  const hasSpace = /\s/;

  // Validate password
  const validatePassword = (value: string) => {
    if (hasSpace.test(value)) {
      return "Password should not contain spaces.";
    }
    if (value.length < MIN_LENGTH) {
      return `Password must be at least ${MIN_LENGTH} and maximum of ${MAX_LENGTH} characters long.`;
    }
    if (!hasUppercase.test(value)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase.test(value)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasSymbol.test(value)) {
      return "Password must contain at least one special character.";
    }
    if (!hasNumber.test(value)) {
      return "Password must contain at least one number.";
    }

    return ""; // Password is valid
  };

  // Edit field
  const [selectedValue, setSelectedValue] = useState("Company");
  const [roomBookFormData, setRoomBookFormData] = useState({
    CompanyName: "",
    bookingFrom: "",
    bookingTo: "",
    customerName: "",
    customerLastName: "",
    contactNo: "",
    email: "",
    address: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    kycNumber: "",
    pincode: "",
    gst: "",
    notes: "",
    password: "",
  });
  const [roomBookErrorFormData, setRoomBookErrorFormData] =
    useState<FormErrors>();
  const [registerRoomBookErrorFormData, setRegisterRoomBookErrorFormData] =
    useState<RegisterFormErrors>();
  const [isCheckRequired, setIsCheckRequired] = useState<ErrorRequired>();
  const [isCheckRegisterRequired, setIsCheckRegisterRequired] =
    useState<ErrorRegisterRequired>();

  // Login flow
  const [loginRoomBookErrorFormData, setLoginRoomBookErrorFormData] =
    useState<LoginFormErrors>();
  const [isCheckLoginRequired, setIsCheckLoginRequired] =
    useState<ErrorLoginRequired>();
  const [roomBookLoginFormData, setRoomBookLoginFormData] = useState({
    bookingUserName: "",
    bookingUserPassword: "",
  });

  // API send
  const [durations, setDurations] = useState([]);
  const [inclusionPackageDetails, setInclusionPackageDetails] = useState("");
  const [exclusionPackageDetails, setExclusionPackageDetails] = useState("");
  var [currentName, setCurrentName] = useState("Contact Person");
  var [currentAddress, setCurrentAddress] = useState("Company");

  const uploadRefs = createRef<Upload>();
  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = (
    <span>
      Only JPEG, JPG, PNG and PDF files are allowed. The maximum upload size is
      2 MB
    </span>
  );
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const [loading, setLoading] = useState(true);
  const [spaceData, setSpaceData] = useState({
    Id: 0,
    SpaceType: "",
    NoOfSeats: 0,
    Description: "",
    DurationId: 0,
    PackageDetailsId: 0,
    RentAmount: 0,
    TaxPercentage: 0,
    SpaceImage: "",
  });
  const [spaceDetailsData, setSpaceDetailsData] = useState<rooms[]>([]);
  const [kycDetailsData, setkycDetailsData] = useState([]);
  const [taxDetailsData, setTaxDetailsData] = useState([]);
  const [subTotal, setsubTotal] = useState(0);
  const [subTotalIncrease, setsubTotalIncrease] = useState(0);
  const [gstPercentage, setGstPercentage] = useState(0);
  const [taxId, setTaxId] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [originalTax, setOriginalTax] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [bookingTotal, setBookingTotal] = useState(0);
  const [dummyTotal, setdummyTotal] = useState(0);
  const [visible, setVisible] = React.useState<boolean>(false);

  //Srikkanth
  const [visibleDialog, setVisibleDialog] = React.useState<boolean>(false);
  const [visibleDetails, setVisibleDetails] = React.useState<boolean>(false);

  //Dani
  const [selectedCard, setSelectedCard] = useState<number | null>(null);
  const hasFetched = useRef(false);
  const [spaceList, setSpaceList] = useState([]);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [availableSlotData, setAvailableSlotData] = useState<{
    [key: string]: SlotInfo;
  }>({});

  const [selectDateScreen, setSelectDateScreen] = useState(false); // screen 1
  const [pickDuration, setPickDuration] = useState(false); // screen 2
  const [isBillDetails, setIsBillDetails] = useState(false); // screen 3

  const [seatsCount, setSeatsCount] = useState<number[]>([]);
  const [monthCount, setMonthCount] = useState<number[]>([]);
  const [selectedSeatsCount, setSelectedSeatsCount] = useState(
    defaultSeatCountValue
  );
  const [selectImage, setSelectedImage] = useState("");
  const [selectImageDescription, setSelectedImageDescription] = useState("");
  const [selectedSpaces, setSelectedSpaces] = useState("");
  const [selectedPackageId, setSelectedPackageId] = useState("1");
  const [selectedSeats, setSelectedSeats] = useState(0);
  const [selectedSpaceId, setSelectedSpaceId] = useState(0);

  const [selectedMonthCount, setSelectedMonthCount] = useState(
    defaultMonthCountValue
  );
  const [isCheckOutDaily, setIsCheckOutDaily] = useState(true);
  const [isCheckHourly, setIsCheckHourly] = useState(true);
  const [isCheckDay, setIsCheckDay] = useState(true);
  const [isCheckMonth, setIsCheckMonth] = useState(true);
  const [isCheckSpaceCount, setIsCheckSpaceCount] = useState(true);
  const [listAvailableSeats, setListAvailableSeats] = useState([]);

  const [loggedIn, setLoggedIn] = useState(false); // State to manage login status
  const [showPopup, setShowPopup] = useState(false); // State to control the popup
  const [logoutDialog, setLogoutDialog] = useState(false); // State to control the popup
  const anchorRef = useRef(null); // Reference to the profile image for positioning popup
  const popupRef = useRef(null); // Reference to the popup for detecting outside clicks

  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [isCheckPassword, setIsCheckPassword] = React.useState(false);
  var isPaymentCheck = false;

  const [changeStartDate, setChangeStartDate] = useState<any>();
  const [spaceTypeId, setSpaceTypeId] = useState(0);
  const [durationDetailsData, setDurationDetailsData] = useState<
    DurationDetails[]
  >([]);

  const [profileImage, setProfileImage] = useState("");
  const [hourlyOpenTime, setHourlyOpenTime] = useState("");
  const [hourlyCloseTime, setHourlyCloseTime] = useState("");

  // Coupon flow
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [couponDialog, setCouponDialog] = useState(false);
  const [discountList, setDiscountList] = useState([]);
  const [discountAmount, setDiscounAmount] = useState(0);
  const [discountId, setDiscountId] = useState(0);
  const [discountName, setDiscountName] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");

  // Country , State & City API flow
  const [countryData, setCountryData] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(defaultCountryValue);
  const [selectedCountryId, setSelectedCountryId] = useState(0);

  const [stateData, setStateData] = useState([]);
  const [selectedState, setSelectedState] = useState(defaultStateValue);
  const [selectedStateId, setSelectedStateId] = useState(0);

  const [cityData, setCityData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(defaultCityValue);
  const [selectedCityId, setSelectedCityId] = useState(0);

  const [countryName, setCountryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [cityName, setCityName] = useState("");

  const hourOnly = (dateString: any) => {
    const dateObject = new Date(dateString);
    const hours = dateObject.getHours();
    return hours.toString().padStart(2, "");
  };

  const getHoursAndMinutes = (dateString: any) => {
    const dateObject = new Date(dateString);
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // Background scroll restriction Srikkanth
  useEffect(() => {
    if (visibleDialog || visibleDetails || pickDuration || couponDialog) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [visibleDialog, visibleDetails, pickDuration, couponDialog]);

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    var savedPreference = localStorage.getItem("name");
    if (savedPreference === "admin") {
      removeUserDetails();
    } else if (savedPreference !== "" && savedPreference !== null) {
      setIsLoginSuccess(true);
      setLoggedIn(true);
      getProfile(); // Profile API Call
    }
    getSpaceList(); // Space API Call
    getKYCDetails(); // KYC Document API Call
    getCountry();
  });

  const getProfile = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${localStorage.getItem(
          "id"
        )} and contains(reftype, 'Profile')`
      );
      if (response.data.value.length !== 0) {
        if (
          response.data.value[response.data.value.length - 1].Name ===
          "A6CC0B0F4DP2012E"
        ) {
          setProfileImage("");
        } else {
          const fileURL: any =
            imageURL +
            response.data.value[response.data.value.length - 1].ImagePath;
          setProfileImage(fileURL);
        }
      } else {
      }
    } catch (err) {
      alert(err);
    }
  };

  const getCountry = async () => {
    try {
      const response = await axios.get(`${baseURL}MasterData/GetAllCountries`);
      setCountryData(response.data.content);
      const defaultCountry = response.data.content.find(
        (current: any) => current.isDefault === true
      );
      setRoomBookFormData((prevData) => ({
        ...prevData,
        ["country"]: defaultCountry.countryName,
      }));
      setCountryName(defaultCountry.countryName);
      setSelectedCountry(defaultCountry);
      setSelectedCountryId(defaultCountry.id);
      getState(defaultCountry.id);
    } catch (err) {
      alert(err);
    }
  };

  const getState = async (countryId: any) => {
    try {
      const response = await axios.get(
        `${baseURL}MasterData/GetAllStates?countryId=${countryId}`
      );
      setStateData(response.data.content);
      if (response.data.content.length > 0) {
        const defaultState = response.data.content.find(
          (current: any) => current.isDefault === true
        );
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["state"]: defaultState.stateName,
        }));
        setStateName(defaultState.stateName);
        setSelectedState(defaultState);
        setSelectedStateId(defaultState.id);
        getCity(defaultState.id);
      } else {
        setStateName("");
        setCityData([]);
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["state"]: "",
        }));
        setSelectedState(defaultStateValue);
        setSelectedStateId(0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getCity = async (stateId: any) => {
    try {
      const response = await axios.get(
        `${baseURL}MasterData/GetAllCities?stateId=${stateId}`
      );
      setCityData(response.data.content);
      if (response.data.content.length > 0) {
        const defaultCity = response.data.content.find(
          (current: any) => current.isDefault === true
        );
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["city"]: defaultCity.cityName,
        }));
        setCityName(defaultCity.cityName);
        setSelectedCity(defaultCity);
        setSelectedCityId(defaultCity.id);
      } else {
        setCityName("");
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["city"]: "",
        }));
        setSelectedCity(defaultCityValue);
        setSelectedCityId(0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Initial load API's
  const getSpaceList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceHeaders?$filter=IsActive eq true&$expand=SpaceDetails($filter=IsActive eq true;$expand=Duration,Tax,PackageDetails)`
      );
      const responseData = response.data.value;
      setSpaceList(responseData);
      getSpaceImageList(responseData);
    } catch (err) {}
  };

  const getSpaceImageList = async (responseData: any) => {
    try {
      const updatedRooms = await Promise.all(
        responseData.map(async (item: rooms) => {
          const imageResponse = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${item.Id}and contains(reftype, 'Space')`
          );
          item.SpaceImage =
            imageResponse.data.value[imageResponse.data.value.length - 1]
              ?.ImagePath || "";
          return item;
        })
      );
      setSpaceDetailsData(updatedRooms);
      setLoading(false);
    } catch (err) {
      alert(err);
    }
  };

  // Space selection
  const handleSpaceSelect = async (index: number) => {
    handleRemoveDiscount();
    setLoading(true);
    setSelectedCard(null);
    setSelectedSpaceId(spaceList[index]["Id"]);
    setSpaceData(spaceList[index]);
    setSelectedCard(index);
    setSpaceTypeId(spaceList[index]["SpaceTypeId"]);
    setHourlyOpenTime(getHoursAndMinutes(spaceList[index]["SpaceOpenTime"]));
    setHourlyCloseTime(getHoursAndMinutes(spaceList[index]["SpaceCloseTime"]));
    localStorage.setItem(
      "hourly_open",
      hourOnly(spaceList[index]["SpaceOpenTime"])
    );
    localStorage.setItem(
      "hourly_close",
      getHoursAndMinutes(spaceList[index]["SpaceCloseTime"])
    );
    if (spaceList[index]["GapTiming"] !== null) {
      sessionStorage.setItem("gap_time", spaceList[index]["GapTiming"]);
    }

    // API call
    getDateData(spaceList[index]["Id"]);
    // getDurationDetails(spaceList[index]["Id"]); New space flow

    // Hide & Reset views based on selected space
    setCheckInDate(null);
    setChangeStartDate(undefined);
    resetSchedulerDatas();

    // New space flow
    setDurationDatas(spaceList[index]);
  };

  const getHours12Format = (dateStr: any) => {
    const date = new Date(dateStr);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const resetSchedulerDatas = () => {
    setIsCheckOutDaily(true);
    setIsCheckMonth(true);
    setIsCheckSpaceCount(true);
    setIsBillDetails(false);
    setSelectedDuration(defaultDurationValue);
    setCheckOutDate(null);
    setsubTotal(0);
    setBookingTotal(0);
    setsubTotalIncrease(0);
    setTotal(0);
    setSelectedSeatsCount("1");
    setSelectedMonthCount("1");
    setSelectedDurationName("");
    setSelectedDurationTypeName("");

    // Delete hourly datas
    setSelectedDuration(defaultDurationValue);
    seStartTimeDilog("");
    setEndTimeDilog("");
    setPickDuration(false);
  };

  // Generate current month 1st and last dates
  const getDateData = (Id: any) => {
    // Get the current date
    var currentDate = new Date();

    // Get the last day of the next month
    var lastDateOfNextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    );

    // Format the start and end dates
    var startDate = formatDate(currentDate); // First day of the previous month
    var endDate = formatDate(lastDateOfNextMonth); // Last day of the next month

    getAvailability(Id, startDate, endDate);
  };

  // Get particular space month available seats list & set scheduler
  const getAvailability = async (
    Id: any,
    currentDate: any,
    lastDateOfMonth: any
  ) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/GetBookingAvailablityByDate?spaceID=${Id}&startDate=${currentDate}&endDate=${lastDateOfMonth}`
      );
      setListAvailableSeats(response.data);
      setBookingAvailability(response.data);
    } catch (err) {
      alert(err);
    }
  };

  const formatDate = (date: any): string => {
    // Format the date as YYYY-MM-DD
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const setBookingAvailability = (data: any) => {
    for (let a = 0; a < data.length; a++) {
      const day = getDayOnly(data[a]["bookedDate"]);
      const [date, time] = data[a]["bookedDate"].split("T");
      addSlot(
        day,
        data[a]["totalNoOfSeats"],
        data[a]["bookedSeats"],
        data[a]["availableSeats"],
        data[a]["bookedFlag"],
        date
      );
    }
    setAvailabilityList(data);
  };

  const getDayOnly = (stringDate: any) => {
    const dateString = stringDate;
    const date = new Date(dateString);
    return String(date.getDate()).padStart(2, "0");
  };

  const addSlot = (
    key: string,
    total: number,
    booked: number,
    available: number,
    bookedFlag: string,
    slotDate: string
  ) => {
    setAvailableSlotData((prevSlotData) => ({
      ...prevSlotData,
      [key]: { total, booked, available, bookedFlag, slotDate },
    }));
  };

  // Modify the CustomSlot component
  const CustomSlot = (props: any) => {
    const current = new Date(); // Current date
    const currentDay = current.getDate();
    const currentMonth = current.getMonth() + 1;
    const currentYear = current.getFullYear();

    const date = props.start.getDate(); // Scheduler data
    const month = props.start.getMonth() + 1;
    const year = props.start.getFullYear();

    const formattedDate = date < 10 ? `0${date}` : date.toString();
    const slotInfo = availableSlotData[formattedDate];

    const isCurrentMonth = currentMonth === month && currentYear === year;
    const isFutureMonth =
      currentYear < year || (currentYear === year && currentMonth < month);
    const isPastMonth =
      currentYear > year || (currentYear === year && currentMonth > month);

    const isCheckInDate =
      checkInDate &&
      checkInDate.getDate() === date &&
      checkInDate.getMonth() + 1 === month &&
      checkInDate.getFullYear() === year;

    let backgroundColor: any;

    // Apply color based on booking status and other conditions
    if (isPastMonth) {
      backgroundColor = whiteColor; // Or any color indicating it's disabled (non-interactable)
    } else if (slotInfo && slotInfo.bookedFlag) {
      switch (slotInfo.bookedFlag) {
        case "G":
          backgroundColor = isCheckInDate ? "lightblue" : greenColor;
          break;
        case "R":
          backgroundColor = redColor;
          break;
        case "A":
          backgroundColor = isCheckInDate ? "lightblue" : yellowColor;
          break;
        default:
          backgroundColor = whiteColor;
      }
    } else {
      if (isCurrentMonth) {
        if (
          currentMonth !== Number(changeStartDate) &&
          changeStartDate !== undefined
        ) {
          backgroundColor = "whitesmoke";
        } else if (currentDay <= date) {
          backgroundColor = isCheckInDate
            ? "lightblue"
            : slotInfo
            ? slotInfo.booked > 0 && slotInfo.booked < slotInfo.total
              ? yellowColor
              : slotInfo.booked >= slotInfo.total
              ? redColor
              : greenColor
            : greenColor;
        } else {
          backgroundColor = whiteColor;
        }
      } else if (isFutureMonth) {
        if (changeStartDate !== undefined) {
          if (Number(changeStartDate) === Number(month)) {
            backgroundColor = isCheckInDate
              ? "lightblue"
              : slotInfo
              ? slotInfo.booked > 0 && slotInfo.booked < slotInfo.total
                ? yellowColor
                : slotInfo.booked >= slotInfo.total
                ? redColor
                : greenColor
              : greenColor;
          } else {
            backgroundColor = "whitesmoke";
          }
        } else if (currentMonth !== month) {
          backgroundColor = "whitesmoke";
        }
      }
    }

    setSaveData(false); // Temp save records removed

    return (
      <SchedulerSlot
        {...props}
        onClick={
          () =>
            backgroundColor !== redColor &&
            backgroundColor !== whiteColor &&
            backgroundColor !== "whitesmoke"
              ? onSlotHandler(props) // If the color is not red, white, or whitesmoke, handle the click
              : null // Do nothing if the color is white, red, or whitesmoke
        }
        style={{
          backgroundColor,
          cursor:
            backgroundColor === whiteColor ||
            backgroundColor === redColor ||
            backgroundColor === "whitesmoke"
              ? "not-allowed"
              : "pointer",
        }}
      >
        {props.children}
      </SchedulerSlot>
    );
  };

  const onSlotHandler = (props: any) => {
    setCheckInDate(props.start);
    const date = new Date(props.start);
    const formattedDate = formatDateToISO(date);
    getHourlyListDetails(props.start);

    setActiveAppointment(null);
    resetSchedulerDatas();
    for (var s = 0; s < listAvailableSeats.length; s++) {
      if (listAvailableSeats[s]["bookedDate"] === formattedDate) {
        setSeatCount(listAvailableSeats[s]["availableSeats"]);
        // if (
        //   listAvailableSeats[s]["spaceId"] === 1 ||
        //   listAvailableSeats[s]["spaceId"] === 2
        // )
        if (spaceTypeId === 1 || spaceTypeId === 2) {
          setIsCheckSpaceCount(false);
        } else {
          setIsCheckSpaceCount(true);
        }
        break;
      }
    }
  };

  const formatDateToISO = (date: any) => {
    // Ensure the date is in the correct format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const setSeatCount = (NoOfSeats: any) => {
    const generatedNumbers = Array.from({ length: NoOfSeats }, (_, i) => i + 1);
    setSeatsCount(generatedNumbers);
    // setIsCheckSpaceCount(false);
  };

  const setMonthsCount = () => {
    const generateMonthdNumbers = Array.from({ length: 12 }, (_, i) => i + 1);
    setMonthCount(generateMonthdNumbers);
  };

  // Register Radio button
  const handleChange = useCallback(
    (e: any) => {
      setSelectedValue(e.value);
      resetDatas(0);
      if (e.value === "Individual") {
        setCurrentName("Your");
        setCurrentAddress("");
        setRoomBookErrorFormData({});
        setRegisterRoomBookErrorFormData({});
        setIsCheckRequired({});
        setIsCheckRegisterRequired({});
      } else {
        setCurrentName("Contact Person");
        setCurrentAddress("Company");
        setRoomBookErrorFormData({});
        setRegisterRoomBookErrorFormData({});
        setIsCheckRequired({});
        setIsCheckRegisterRequired({});
      }
    },
    [setSelectedValue]
  );

  const resetDatas = (selection: any) => {
    if (selection === 1) {
      setIsCheckHourly(true);
      const errors: FormErrors = {};
      errors.spaceDateErr = "";
      errors.spaceDurationErr = "";
      setsubTotalIncrease(0);
      setTaxAmount(0);
      setTotal(0);
      setdummyTotal(0);
      setDepositAmount(0);
      setSelectedDuration(defaultDurationValue);
      setCheckInDate(null);
      setCheckOutDate(null);
      setSelectedFromTime(defaultFromTimeValue);
      setSelectedToTime(defaultToTimeValue);
      setRoomBookErrorFormData(errors);
      setRegisterRoomBookErrorFormData(errors);
    }
    resetRegisterDatas();
  };

  // Save file upload local
  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    const uid = currentFile.uid;

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  // Remove file upload local
  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  // function removeUploadFile(): Promise<{ uid: string }> {
  //   console.log("Image Data->", currentFileId);
  //   setImageData("");
  //   return new Promise<{ uid: string }>((resolve) => {
  //     resolve({ uid: currentFileId });
  //   });
  // }

  // Get space package details API
  const getPackageDetails = async (packageId: String) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/PackageDetails(${packageId})`
      );
      setInclusionPackageDetails(response.data.value[0].Inclusion);
      setExclusionPackageDetails(response.data.value[0].Exclusion);
      setSelectDateScreen(true);
    } catch (err) {}
  };

  // Get duration API
  const setDurationDatas = (responseData: any) => {
    const duration: any[] = [];
    const durationList = responseData.SpaceDetails;
    setDurations(durationList);
    for (let d = 0; d < durationList.length; d++) {
      const durationHeaderData = {
        Id: durationList[d].Id,
        SpaceHeaderId: durationList[d].SpaceHeaderId,
        DurationId: durationList[d].DurationId,
        PackagaDetailsId: durationList[d].PackagaDetailsId,
        CancellationPolicyDetails: durationList[d].CancellationPolicyDetails,
        DepositAmount: durationList[d].DepositAmount,
        RentAmount: durationList[d].RentAmount,
        TaxPercentage: durationList[d].Tax.TemplatePercentage,
        IsActive: durationList[d].IsActive,
        TaxId: durationList[d].Tax.Id,

        DurationName: durationList[d].Duration.DurationType,
        DurationTypeId: durationList[d].Duration.DurationTypeId,
        HasVarient: durationList[d].Duration.HasVarient,

        Varient1: "",
        Varient2: "",
        DurationIsActive: true,
        VarientName: "",
        VarientNumber: 0,
        VariantId:
          durationList[d].Duration.VarinetId === null
            ? 0
            : durationList[d].Duration.VarinetId,
        durationTypeName: "",
        DurationTypeIsActive: true,
        inclusionPackage: durationList[d].PackageDetails.Inclusion,
        exclusionPackage: durationList[d].PackageDetails.Exclusion,
        packageName: durationList[d].PackageDetails.PackageName,
      };
      duration.push(durationHeaderData);
    }
    getDurationTypeDetails(duration);
  };

  const getDurationDetails = async (spaceId: String) => {
    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceDetails?$filter=(SpaceHeaderId eq ${spaceId})and(IsActive eq true)`
      );
      const durations: any[] = [];
      const responseData = response.data.value;
      setDurations(responseData);
      for (let d = 0; d < responseData.length; d++) {
        const durationData = {
          Id: responseData[d].Id,
          SpaceHeaderId: responseData[d].SpaceHeaderId,
          DurationId: responseData[d].DurationId,
          PackagaDetailsId: responseData[d].PackagaDetailsId,
          CancellationPolicyDetails: responseData[d].CancellationPolicyDetails,
          DepositAmount: responseData[d].DepositAmount,
          RentAmount: responseData[d].RentAmount,
          TaxPercentage: responseData[d].TaxPercentage,
          IsActive: responseData[d].IsActive,
          TaxId: responseData[d].TaxId,
          DurationName: "",
          DurationTypeId: 0,
          HasVarient: 0,
          Varient1: "",
          Varient2: "",
          DurationIsActive: true,
          durationTypeName: "",
          DurationTypeIsActive: true,
        };
        durations.push(durationData);
      }
      getDurationAPI(durations);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  const getDurationAPI = async (responseData: any) => {
    try {
      const updatedDurations = await Promise.all(
        responseData.map(async (item: DurationDetails) => {
          const durationResponse = await axios.get(
            `${baseURL}odata/Durations(${item.DurationId})`
          );
          item.DurationName = durationResponse.data.value[0].DurationType;
          item.DurationTypeId = durationResponse.data.value[0].DurationTypeId;
          item.HasVarient = durationResponse.data.value[0].HasVarient;
          item.VarientName = "";
          item.VarientNumber = 0;
          item.VariantId =
            durationResponse.data.value[0].VarinetId === null
              ? 0
              : durationResponse.data.value[0].VarinetId;
          item.DurationIsActive = durationResponse.data.value[0].IsActive;
          return item;
        })
      );
      getDurationTypeDetails(updatedDurations);
    } catch (err) {
      // setLoading(false);
      alert(err);
    }
  };

  const getDurationTypeDetails = async (responseData: any) => {
    try {
      const updatedDurationTypes = await Promise.all(
        responseData.map(async (item: DurationDetails) => {
          const response = await axios.get(
            `${baseURL}odata/DurationTypes(${item.DurationTypeId})`
          );
          item.durationTypeName = response.data.duration_type;
          item.DurationTypeIsActive = response.data.IsActive;
          return item;
        })
      );
      getDurationVariantAPI(updatedDurationTypes);
    } catch (err) {
      // setLoading(false);
      alert(err);
    }
  };

  const getDurationVariantAPI = async (responseData: any) => {
    try {
      const updatedDurationVariant = await Promise.all(
        responseData.map(async (item: DurationDetails) => {
          const durationVariantResponse = await axios.get(
            `${baseURL}odata/GetAllDurationVarientType`
          );
          for (let v = 0; v < durationVariantResponse.data.length; v++) {
            if (item.VariantId === durationVariantResponse.data[v].id) {
              item.VarientName = durationVariantResponse.data[v].varientName;
              item.VarientNumber = durationVariantResponse.data[v].totalHours;
              break;
            }
          }
          return item;
        })
      );
      setDurationDetailsData(updatedDurationVariant);
      setSelectDateScreen(true);
      setLoading(false);
      // Selected Space
      window.setTimeout(() => {
        window.scroll({
          top: 640,
          left: 0,
          behavior: "smooth",
        });
      }, 5);
    } catch (err) {
      // setLoading(false);
      alert(err);
    }
  };

  // Get KYC document API
  const getKYCDetails = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/KycDocuments`);
      setkycDetailsData(response.data.value);
      // getTaxDetails();
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  // Get Tax API
  const getTaxDetails = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/GetAllTaxType?$filter=IsActive%20eq%20true`
      );
      setTaxDetailsData(response.data);
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  // Billing details
  useEffect(() => {
    if (checkInDate !== null && selectedDurationTypeName !== undefined) {
      if (selectedDurationTypeName === "Quarterly") {
        formatter(checkInDate, 3);
      } else if (selectedDurationTypeName === "Yearly") {
        formatter(checkInDate, 12);
      } else if (
        selectedDurationTypeName === "Daily" &&
        checkOutDate !== null
      ) {
        dateFormatter(checkInDate, checkOutDate);
      } else if (selectedDurationTypeName === "Hourly") {
        hourFormatter(checkInDate);
      } else {
        formatter(checkInDate, selectedMonthCount);
      }
    }
  }, [checkInDate, selectedDurationTypeName, selectedMonthCount, checkOutDate]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const formatter = (dat: any, monthCount: any) => {
    const resultDate = new Date(dat);
    resultDate.setMonth(dat.getMonth() + parseInt(monthCount));

    // Function to manually format date as "dd MMM yyyy"
    const formatDate = (date: Date) => {
      const day = date.getDate().toString().padStart(2, "0"); // Get day and pad with '0' if needed
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const month = monthNames[date.getMonth()]; // Get the month in short form
      const year = date.getFullYear(); // Get the full year

      return `${day} ${month} ${year}`; // Return the formatted date
    };

    const formattedToday = formatDate(dat); // Format the current date
    const formattedResultDate = formatDate(resultDate); // Format the result date
    setStartDate(formattedToday);
    setEndDate(formattedResultDate);
  };

  const hourFormatter = (dat: any) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedToday = dat.toLocaleDateString("en-GB", options);
    setStartDate(formattedToday);
  };

  const dateFormatter = (dat: any, datu: any) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get day of the week, day of the month, month, and year
    const day1 = String(dat.getDate()).padStart(2, "0"); // Adds leading zero if necessary (e.g., "18")
    const month1 = months[dat.getMonth()]; // E.g., "Sep"
    const year1 = dat.getFullYear(); // E.g., "2024"

    const day2 = String(datu.getDate()).padStart(2, "0"); // Adds leading zero if necessary (e.g., "18")
    const month2 = months[datu.getMonth()]; // E.g., "Sep"
    const year2 = datu.getFullYear(); // E.g., "2024"
    setStartDate(`${day1}-${month1}-${year1}`);
    setEndDate(`${day2}-${month2}-${year2}`);
  };

  const calculateDifferenceDates = (date1: any, date2: any) => {
    const start = new Date(date1);
    const end = new Date(date2);
    const differenceInTime = end.getTime() - start.getTime();
    const totalDays = differenceInTime / (1000 * 3600 * 24);
    const addedCount = totalDays + 1;
    setSelectedMonthCount(addedCount.toString());
    return addedCount;
  };

  const calculateBookingAmount = (
    RentAmount: any,
    Tax: any,
    checkFlow: any,
    difference: any,
    deposit: any,
    seats: any,
    months: any
  ) => {
    let subTotalValue = RentAmount;
    let gstValue = Tax;
    let depositValue = deposit;
    if (checkFlow === "Others") {
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstValue) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setTotal(calculateTotal);
      setBookingTotal(calculateTotal);
      const addedDiscount = calculateTotal + depositValue;
      setdummyTotal(addedDiscount);
    } else if (checkFlow === selectedDurationID && difference !== 0) {
      // need check
      let subTotalValue = subTotal * difference;
      setsubTotalIncrease(subTotalValue);
      const calculateTax = (subTotalValue * gstPercentage) / 100;
      const calculateTotal = subTotalValue + calculateTax;
      setTaxAmount(calculateTax);
      setTotal(calculateTotal);
      setBookingTotal(calculateTotal);
      const addedDiscount = calculateTotal + depositValue;
      setdummyTotal(addedDiscount);
    } else {
      setsubTotalIncrease(0);
      setTaxAmount(0);
      setTotal(0);
      setBookingTotal(0);
      setdummyTotal(0);
    }
  };

  // Any to server date conversion
  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    return isoFormattedDate;
  };

  const [alertContent, setAlertContent] = React.useState<String>("");
  // Save booking details API
  const savePersonalDetails = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const spaceDiscountAmount = Number(
      (
        bookingTotal -
        (bookingTotal - calculateBaseAmount(bookingTotal))
      ).toFixed(2)
    );

    let spaceAmount = spaceDiscountAmount;

    let dummyData = {
      userId: Number(localStorage.getItem("id")),
      roomTypeId: spaceData.Id,
      taxId: Number(taxId),
      bookingId: "",
      bookingDatetime: convertToISOFormat(new Date()),
      noOfSeats: parseInt(selectedSeatsCount),
      bookingForId: 2,
      // address: localStorage.getItem("address1"),
      address: localStorage.getItem("address"),
      companyName: localStorage.getItem("company_name"),
      kycDocumentId: localStorage.getItem("kyc_document_id"),
      gstNumber: localStorage.getItem("gst_number"),
      customerId: localStorage.getItem("customerId"),
      customerName: localStorage.getItem("name"),
      customerType:
        Number(localStorage.getItem("customer_type")) === 1
          ? "Company"
          : "Individual",
      contactNo: Number(localStorage.getItem("mobile")),
      email: localStorage.getItem("email"),
      totalAmount: totalPercentageCalculation(),
      discountPercentage: discountTypeId === 1 ? 0 : discountAmount,
      discountValue:
        discountTypeId === 1
          ? Number(discountFixedCalculation())
          : Number(discountPercentageCalculation()),
      discountId: discountId,
      packageSelection: selectedPackageId.toString(),
      bookingFrom:
        startTimeDilog === ""
          ? convertToISOFormat(checkInDate)
          : convertToISOFormat(startTimeDilog),
      bookingTo:
        endTimeDilog === ""
          ? convertToISOFormat(checkOutDate)
          : convertToISOFormat(endTimeDilog),
      bookingStatus: 2,
      paymentStatus: 3,
      deposit: depositAmount,
      spaceRent: spaceAmount,

      otherAmount: 0,
      rawpowerStartingUnit: 0,
      rawpowerClosingUnit: 0,
      upspowerStartingUnit: 0,
      upspowerClosingUnit: 0,
      totalUnitConsumed: 0,
      perUnitCost: 0,
      overallCharges: 0,
      coffeeTea: 0,
      meals: 0,
      miscellaneous: 0,
      needToCollect: 0,
      refund: 0,
      durationId: selectedDurationID,
      spaceId: spaceData.Id,
      noOfMonths:
        Number(selectedDurationTypeID) === 3 ? parseInt(selectedMonthCount) : 0, // need check
    };
    // console.log("booking", dummyData);
    try {
      const response = await axios.post(`${baseURL}odata/Bookings`, dummyData, {
        headers: header,
      });
      if (response.status === 201) {
        // sendMail(response.data.Id);
        createOrder(response.data.Id); // Razor pay payment flow
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
        setLoading(false);
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        setVisible(true);
        setAlertContent(`${err.response.data.detail}`);
        setLoading(false);
      }
    }
  };

  const createOrder = async (Id: any) => {
    try {
      const response = await axios.post(
        `${baseURL}odata/CreateOrder?amount=${totalPercentageCalculation()}`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        razorPayMode(Id, response.data.orderId);
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
        setLoading(false);
      }
    } catch (err: any) {
      if (err.response.status === 500) {
        setVisible(true);
        setAlertContent(`${err.response.data.detail}`);
        setLoading(false);
      }
    }
  };

  const razorPayMode = async (Id: any, orderId: any) => {
    isPaymentCheck = false;
    setLoading(false);
    var options = {
      key: razorPayKey(),
      key_secret: razorPaySecret(),
      amount: totalPercentageCalculation() * 100,
      currency: "INR",
      image:
        "https://static.wixstatic.com/media/314ae5_8999bfcce3de456e93e30c3b18fa11ca~mv2.jpg/v1/fill/w_217,h_209,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/wfh_icon_dp.jpg",
      name: "workfromhaven",
      description: "Book a Space",
      order_id: orderId,
      handler: function (response: any) {
        setLoading(true);
        saveOrder(
          response.razorpay_order_id,
          response.razorpay_payment_id,
          Id,
          response.razorpay_signature
        );
      },
      prefill: {
        email: localStorage.getItem("email"),
        contact: localStorage.getItem("mobile"),
      },
      notes: {
        // address: localStorage.getItem("address1"),
        address: localStorage.getItem("address"),
      },
      theme: {
        color: "#42b677",
      },
      retry: {
        enabled: false,
      },
      timeout: 900,
      modal: {
        escape: true,
        ondismiss: function () {
          if (isPaymentCheck === false) {
            setLoading(true);
            saveOrder(orderId, "", Id, "");
          }
        },
      },
    };
    var pay = new (window as any).Razorpay(options);
    pay.on("modal.closed", function (response: any) {
      setLoading(true);
      isPaymentCheck = true;
      saveOrder(orderId, response.error.metadata.payment_id, Id, "");
    });
    pay.on("payment.failed", function (response: any) {
      setLoading(true);
      isPaymentCheck = true;
      saveOrder(orderId, response.error.metadata.payment_id, Id, "");
    });
    pay.open();
  };

  const saveOrder = async (
    order_id: any,
    payment_id: any,
    Id: any,
    razorpay_signature: any
  ) => {
    const totalAmount = totalPercentageCalculation();
    try {
      const response = await axios.post(
        `${baseURL}odata/SaveOrder?bookingId=${Id}&orderId=${order_id}&transactionId=${payment_id}&amount=${totalAmount}`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        completeOrder(order_id, payment_id, Id, razorpay_signature);
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      alert(err);
    }
  };

  const completeOrder = async (
    order_id: any,
    payment_id: any,
    Id: any,
    razorpay_signature: any
  ) => {
    const totalAmount = totalPercentageCalculation();
    try {
      const response = await axios.post(
        `${baseURL}odata/CompleteOrder?BookingId=${Id}&Amount=${totalAmount}&PaymentId=${payment_id}&PaymentStatus=${
          razorpay_signature === "" ? "Failed" : "Completed"
        }&OrderId=${order_id}&Signature=${razorpay_signature}`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        if (razorpay_signature === "") {
          if (discountId === 0) {
            setLoading(false);
            resetDatas(1);
            resetSchedulerDatas();
            setSelectDateScreen(false);
            setSelectedCard(null);
            setVisible(true);
            setAlertContent("Payment failed. Please try again");
          } else {
            updateDiscountStatus(Id);
          }
        } else {
          sendMail(Id);
        }
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      alert(err);
    }
  };

  // Mail send API
  const sendMail = async (bookingId: any) => {
    try {
      const response = await axios.post(
        `${baseURL}Odata/SendConfirmationEmail?email=${localStorage.getItem(
          "email"
        )}&bookingId=${bookingId}`,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        setVisible(false);
        setAlertContent(response.data.message);
        resetDatas(1);
        resetSchedulerDatas();
        setSelectDateScreen(false);
        setSelectedCard(null);
        setVisible(true);
        setAlertContent(response.data.message);
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  // Remove discount API
  const updateDiscountStatus = async (bookingId: any) => {
    const discountJson = {
      bookingId: bookingId,
      userId: localStorage.getItem("id"),
    };

    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateUserDiscountMapping`,
        discountJson,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        setLoading(false);
        resetDatas(1);
        resetSchedulerDatas();
        setSelectDateScreen(false);
        setSelectedCard(null);
        setVisible(true);
        setAlertContent("Payment failed. Please try again");
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
  };

  // All fields handle changes
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "contactNo") {
      if (/^\d*$/.test(value)) {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      // if (name === "email" || name === "address1" || name === "address2") {
      if (name === "email" || name === "address" || name === "address2") {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (name === "gst") {
        const value = e.target.value.toUpperCase();
        // Allow only alphanumeric characters
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value.replace(/[^a-zA-Z0-9]/g, ""),
        }));
      } else if (
        name === "companyName" ||
        name === "firstName" ||
        name === "lastName"
      ) {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value.replace(/[^A-Za-z ]/, ""),
        }));
      } else if (name === "kycNumber") {
        setRoomBookFormData({
          ...roomBookFormData,
          [name]: value,
        });
      } else if (name === "pincode") {
        if (/^\d*$/.test(value)) {
          setRoomBookFormData({
            ...roomBookFormData,
            [name]: value,
          });
        }
      } else if (name === "password") {
        setIsCheckPassword(isCheckPassword);
        setPassword(value);
        const error = validatePassword(value);
        setPasswordValidationError(error);
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else if (name === "confirmPassword") {
        setConfirmPassword(value);
      } else {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          [name]: value.replace(/[^A-Za-z ]/gi, ""),
        }));
      }
    }
  };

  const [hourlyVariantName, setHourlyVariantName] = useState("");
  const [hourlyVariantAmount, setHourlyVariantAmount] = useState(0);
  const handleDropdownChange = (e: DropDownListChangeEvent) => {
    const { name, value } = e.target;
    if (name === "Name") {
      setSelectedKYC(e.value);
      setSelectedKYCID(e.value.Id);
      setSelectedKYCName(e.value.Name);
      setRoomBookFormData({
        ...roomBookFormData,
        ["kycNumber"]: "",
      });
      setIsCheckRegisterRequired((prevState) => ({
        ...prevState,
        customerKycNumberErrr: true,
      }));
      setRegisterRoomBookErrorFormData((prevState) => ({
        ...prevState,
        customerKycNumberErrr: "",
      }));
    } else if (name === "countryName") {
      setCountryName("");
      setRoomBookFormData((prevData) => ({
        ...prevData,
        ["country"]: e.value.countryName,
      }));
      setSelectedCountry(e.value);
      setSelectedCountryId(e.value.id);
      getState(e.value.id);
    } else if (name === "stateName") {
      setStateName("");
      setRoomBookFormData((prevData) => ({
        ...prevData,
        ["state"]: e.value.stateName,
      }));
      setSelectedState(e.value);
      setSelectedStateId(e.value.id);
      getCity(e.value.id);
    } else if (name === "cityName") {
      setCityName("");
      setRoomBookFormData((prevData) => ({
        ...prevData,
        ["city"]: e.value.cityName,
      }));
      setSelectedCity(e.value);
      setSelectedCityId(e.value.id);
    } else if (name === "seats") {
      setSelectedSeatsCount(e.value);
      calculateMonthSeats(e.value, selectedMonthCount);
    } else if (name === "month") {
      setSelectedMonthCount(e.value);
      calculateMonthSeats(selectedSeatsCount, e.value);
    } else if (name === "bookingFrom") {
      setRoomBookFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      if (e.value === "Select From Time") {
        setRoomBookFormData((prevData) => ({
          ...prevData,
          ["bookingTo"]: "Select To Time",
        }));
        setSelectedFromTime(defaultFromTimeValue);
      } else {
        setSelectedFromTime(e.value);
      }
      setSelectedToTime(defaultToTimeValue);
    } else {
      resetHourlyCalculations(false);
      // Duration flow
      handleRemoveDiscount();
      if (e.value.VariantId !== 0) {
        setHourlyVariantName(e.value.VarientName);
        // setHourlyVariantAmount(
        //   Number(e.value.RentAmount) * Number(e.value.VarientNumber)
        // );
        setHourlyVariantAmount(Number(e.value.RentAmount)); // Varient
        localStorage.setItem("variant_number", e.value.VarientNumber);
      } else {
        setHourlyVariantName("");
        setHourlyVariantAmount(0);
        localStorage.setItem("variant_number", "");
      }
      setIsBillDetails(false);
      setCheckOutDate(null);
      setActiveAppointment(null);
      setSelectedMonthCount("1");
      if (e.value.DurationTypeId === 2) {
        setIsCheckOutDaily(false);
        seStartTimeDilog("");
        setEndTimeDilog("");
      } else {
        setIsCheckOutDaily(true);
      }
      setSelectedSeatsCount("1");
      setSelectedDuration(e.value);
      setSelectedDurationName(e.value.DurationName);
      setSelectedDurationTypeName(e.value.durationTypeName);
      if (e.value.DurationName === "Select Duration") {
        calculateBookingAmount(0, 0, "", 0, 0, 0, 0);
        setDepositAmount(0);
        setPickDuration(true);
      } else if (e.value.DurationTypeId === 1) {
        // 1
        setsubTotal(e.value.RentAmount);
        setGstPercentage(e.value.TaxPercentage);
        setPickDuration(true);
        setSelectedDurationID(e.value.DurationId);
        setSelectedDurationTypeID(e.value.DurationTypeId);
        setDepositAmount(e.value.DepositAmount);
        // getPackageDetails(e.value.PackagaDetailsId);
        setInclusionPackageDetails(e.value.inclusionPackage);
        setExclusionPackageDetails(e.value.exclusionPackage);
        setSelectedPackageId(e.value.PackagaDetailsId);
        setTaxId(e.value.TaxId);
        const taxValue: string = e.value.TaxPercentage;
        setOriginalTax(taxValue);
        let numericValue = checkPercentage(taxValue);
        setTaxPercentage(numericValue);

        // for (let t = 0; t < taxDetailsData.length; t++) {
        //   if (Number(e.value.TaxId) === taxDetailsData[t]["id"]) {
        // setTaxId(taxDetailsData[t]["id"]);
        // const taxValue: string = taxDetailsData[t]["name"];
        // setOriginalTax(taxValue);
        // let numericValue = checkPercentage(taxValue);
        // setTaxPercentage(numericValue);
        calculateBookingAmount(
          0,
          numericValue,
          e.value.Id,
          0,
          e.value.DepositAmount,
          selectedSeatsCount,
          selectedMonthCount
        );
        //     break;
        //   }
        // }
      } else {
        setHourlyData([]);
        setPickDuration(false);
        setsubTotal(e.value.RentAmount);
        setGstPercentage(e.value.TaxPercentage);
        setIsCheckHourly(true);
        setSelectedFromTime(defaultFromTimeValue);
        setSelectedToTime(defaultToTimeValue);
        setSelectedDurationID(e.value.DurationId);
        setSelectedDurationTypeID(e.value.DurationTypeId);
        setDepositAmount(e.value.DepositAmount);
        // getPackageDetails(e.value.PackagaDetailsId);
        setInclusionPackageDetails(e.value.inclusionPackage);
        setExclusionPackageDetails(e.value.exclusionPackage);
        setSelectedPackageId(e.value.PackagaDetailsId);
        setTaxId(e.value.TaxId);
        const taxValue: string = e.value.TaxPercentage;
        setOriginalTax(taxValue);
        let numericValue = checkPercentage(taxValue);
        setTaxPercentage(numericValue);

        // for (let t = 0; t < taxDetailsData.length; t++) {
        //   if (Number(e.value.TaxId) === taxDetailsData[t]["id"]) {
        //     setTaxId(taxDetailsData[t]["id"]);
        //     const taxValue: string = taxDetailsData[t]["name"];
        //     setOriginalTax(taxValue);
        //     let numericValue = checkPercentage(taxValue);
        //     setTaxPercentage(numericValue);
        calculateBookingAmount(
          e.value.RentAmount,
          numericValue,
          "Others",
          0,
          e.value.DepositAmount,
          selectedSeatsCount,
          selectedMonthCount
        );
        //     break;
        //   }
        // }

        // Get the current time
        const currentTime = new Date();
        const currentHour = currentTime.getHours();
        if (e.value.DurationTypeId === 1 && currentHour >= 11) {
          // 2
          setIsCheckDay(false);
        } else {
          setIsCheckDay(true);
        }
        if (e.value.DurationTypeId == 3) {
          // 3
          setIsCheckMonth(false);
          setMonthsCount();
        } else {
          setIsCheckMonth(true);
        }
      }
    }
  };

  const checkPercentage = (taxValue: any) => {
    let numericValue;

    numericValue = taxValue;

    return numericValue;
  };

  const calculateMonthSeats = (seatsValue: any, monthValue: any) => {
    const bookingTotal = total * seatsValue * monthValue;
    setBookingTotal(bookingTotal);
  };

  const setTimeCalc = (value: any) => {
    const curDate = new Date();
    settoTimeEnable(true);
    setSelectedToTime(defaultToTimeValue);
    setSelectedFromTime(defaultFromTimeValue);
    if (
      curDate.getDate() === value.getDate() &&
      curDate.getMonth() === value.getMonth() &&
      curDate.getFullYear() === value.getFullYear()
    ) {
      setOrginalTime(curDate.getHours() + 1);
    } else {
      setOrginalTime(8);
    }
  };

  const [totimeEnable, settoTimeEnable] = useState(true);

  //Timelist for from and to time
  const generateTimeList = (): string[] => {
    const times: string[] = [];
    let currentTime = new Date();

    currentTime.setHours(orginalTime, 0, 0, 0); // Start at 08:00

    const endTime = new Date();
    endTime.setHours(19, 0, 0, 0); // End at 19:00

    while (currentTime <= endTime) {
      const timeString = currentTime.toLocaleTimeString([], {
        hour: "2-digit", // 2-digit hour (leading zero)
        minute: "2-digit",
        hour12: false, // 24-hour format
      });

      times.push(timeString);
      currentTime.setMinutes(currentTime.getMinutes() + 60); // Increment by 60 minutes
    }
    return times;
  };

  const timeList = generateTimeList();

  const handleToTimeChange = (event: any) => {
    const selectedToTime = event.target.value;
    setSelectedToTime(selectedToTime);
    const { name, value } = event.target;
    setRoomBookFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    let fromTime: String = selectedFromTime!;
    // Parse hours and minutes from the time strings
    const [fromHours, fromMinutes] = fromTime.split(":").map(Number);
    const [toHours, toMinutes] = selectedToTime.split(":").map(Number);

    // Create Date objects for fromTime and toTime
    let fromDate = new Date();
    fromDate.setHours(fromHours, fromMinutes, 0, 0);

    let toDate = new Date();
    toDate.setHours(toHours, toMinutes, 0, 0);

    // Calculate the difference in hours
    const hoursDifference =
      (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
    calculateBookingAmount(
      0,
      0,
      selectedDurationID,
      hoursDifference,
      depositAmount,
      selectedSeatsCount,
      selectedMonthCount
    );
  };

  const calculateHoursDifference = (
    fromTime: string,
    toTime: string
  ): number => {
    const [fromHours, fromMinutes, fromPeriod] = fromTime.split(/[: ]/);
    let fromDate = new Date();
    fromDate.setHours(
      fromPeriod === "AM" ? parseInt(fromHours) : parseInt(fromHours) + 12,
      parseInt(fromMinutes),
      0,
      0
    );

    const [toHours, toMinutes, toPeriod] = toTime.split(/[: ]/);
    let toDate = new Date();
    toDate.setHours(
      toPeriod === "AM" ? parseInt(toHours) : parseInt(toHours) + 12,
      parseInt(toMinutes),
      0,
      0
    );

    return (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60);
  };

  const getFilteredToTimeList = (): string[] => {
    if (!selectedFromTime) return [];

    const filteredTimes = timeList.filter((time) => {
      const hoursDifference = calculateHoursDifference(selectedFromTime, time);
      return hoursDifference >= 1 && hoursDifference <= 5;
    });
    return filteredTimes;
  };

  const toggleDialog = () => {
    setVisible(!visible);
  };

  function handleEdit(event: any) {
    window.setTimeout(() => {
      window.scroll({
        top: 1000,
        left: 0,
        behavior: "smooth",
      });
    }, 5);
  }

  // // Handle date navigation changes monthly scheduler
  const handleDateChange = (event: any) => {
    sendAvailabilityAPI(event);
  };

  const sendAvailabilityAPI = (event: any) => {
    try {
      // Get the current date
      var currentDate = new Date(event.value);

      // Force the "current month" to be the first day of the month (1st day of the current month)
      var firstDayOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );

      // Get the first day of the previous month
      var firstDateOfPreviousMonth = new Date(
        firstDayOfCurrentMonth.getFullYear(),
        firstDayOfCurrentMonth.getMonth(),
        1
      );

      // Get the last day of the next month
      var lastDateOfNextMonth = new Date(
        firstDayOfCurrentMonth.getFullYear(),
        firstDayOfCurrentMonth.getMonth() + 1,
        0
      );

      // Format the start and end dates
      var startDate = formatDate(firstDateOfPreviousMonth); // First day of the previous month
      var endDate = formatDate(lastDateOfNextMonth); // Last day of the next month

      setChangeStartDate(
        firstDateOfPreviousMonth.toLocaleDateString("en-GB", {
          month: "2-digit",
        })
      );
      getAvailability(selectedSpaceId, startDate, endDate);
    } catch (error) {
      console.error("Error calculating dates:", error);
    }
  };

  // Hours scheduler
  const [hourlyData, setHourlyData] = useState<any[]>([]);
  const [hourlyDataOnce, setHourlyDataOnce] = useState<any[]>([]);

  const currentYear = new Date().getFullYear();
  const parseAdjust = (eventDate: any) => {
    const date = new Date(eventDate);
    date.setFullYear(currentYear);
    return date;
  };

  const hourlyBookingModelFields = {
    start: "Start",
    end: "End",
  };

  const getHourlyListDetails = async (datasHourlys: any) => {
    const sendData = formatDate(datasHourlys);
    try {
      const response = await axios.get(
        `${baseURL}odata/GetAllHourlyBookingsByDate?spaceId=${selectedSpaceId}&startDate=${sendData}&endDate=${sendData}`
      );
      if (response.status === 200) {
        const hourlyDatas = response.data || [];

        const sampleDataWithCustomSchema = hourlyDatas.map((dataItem: any) => ({
          ...dataItem,
          AppointmentID: dataItem.id,
          title: dataItem.customerName,
          Start: parseAdjust(dataItem.bookingFrom),
          End: parseAdjust(dataItem.bookingTo),
          isActive: false,
        }));

        // // Transforming data to match the Kendo Scheduler schema
        // const sampleDataWithCustomSchema = hourlyDatas.map((dataItem: any) => ({
        //   // AppointmentID: dataItem.id, // Unique ID
        //   // title: dataItem.customerName, // Title for the event
        //   Start: parseAdjust(dataItem.bookingFrom), // Start date
        //   End: parseAdjust(dataItem.bookingTo), // End date
        //   isAllDay: false,
        //   // isActive: false, // Custom property (optional)
        // }));

        setHourlyData(sampleDataWithCustomSchema);
        setHourlyDataOnce(sampleDataWithCustomSchema);
      }
    } catch (err) {
      alert(err);
    }
  };

  const [activeAppointment, setActiveAppointment] = useState(null); // Track the active editable record

  const addMinutes = (date: any, minutes: any) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  // Handle CRUD operations
  const handleDataChange = ({
    created,
    updated,
    deleted,
  }: SchedulerDataChangeEvent) => {
    // Function to check if the new booking overlaps with existing bookings
    const isTimeSlotConflict = (newBooking: any) => {
      return hourlyData.some((existingBooking) => {
        return (
          newBooking.Start < existingBooking.End &&
          newBooking.End > existingBooking.Start
        );
      });
    };

    // Check if any new item is being created
    if (created.length > 0) {
      const newBooking = created[0];
      if (isTimeSlotConflict(newBooking)) {
        setVisible(true);
        setAlertContent(
          "This time slot is already booked. Please select a different time."
        );
        return; // Prevent new booking from being added
      } else if (sessionStorage.getItem("gap_time") !== null) {
        newBooking.End = addMinutes(
          newBooking.End,
          Number(sessionStorage.getItem("gap_time"))
        );
        if (isTimeSlotConflict(newBooking)) {
          setVisible(true);
          setAlertContent(
            `After a time slot is booked, there must be a minimum gap of ${sessionStorage.getItem(
              "gap_time"
            )} minutes. Please select a different time.`
          );
          return; // Prevent new booking from being added
        }
      }

      newBooking.End = addMinutes(
        newBooking.End,
        -Number(sessionStorage.getItem("gap_time"))
      );
      // Deactivate all previous records and add the new booking
      const newHourlyData = hourlyData.map((item) => ({
        ...item,
        isActive: false, // Disable all previous records for editing/deleting
      }));

      setHourlyData([
        ...newHourlyData,
        ...created.map((item) => ({
          ...item,
          AppointmentID: 0,
          isActive: true, // Only the new item is active
        })),
      ]);

      setActiveAppointment(created[0]); // Set the newly created appointment as active
    }

    // Handle updates (if the active record is edited)
    if (updated.length > 0) {
      if (updated[0].AppointmentID === 0) {
        setHourlyData((oldData) =>
          oldData.map(
            (item) =>
              updated.find(
                (current) => current.AppointmentID === item.AppointmentID
              ) || item
          )
        );
      } else {
        setVisible(true);
        setAlertContent("You cannot update default booked slot");
      }
    }

    // Handle deletions
    if (deleted.length > 0) {
      if (deleted[0].AppointmentID === 0) {
        setHourlyData((oldData) =>
          oldData.filter(
            (item) =>
              !deleted.find(
                (current) => current.AppointmentID === item.AppointmentID
              )
          )
        );
        setActiveAppointment(null); // Reset active appointment after deletion
      } else {
        setVisible(true);
        setAlertContent("You cannot delete default booked slot");
      }
    }
  };

  const HourlySlot = (props: any) => {
    const cur = new Date();
    const slotStart = new Date(props.start);

    const isPastSlot = slotStart < cur; // Check if the slot is in the past
    const isFutureSlot = slotStart >= cur; // Check if the slot is in the future

    // Background color logic
    const backgroundColor = isPastSlot
      ? whiteColor // Gray for past slots (view-only)
      : props.slotInfo
      ? props.slotInfo.booked === props.slotInfo.total ||
        props.slotInfo.booked > props.slotInfo.total
        ? redColor // Red if fully booked or overbooked
        : props.slotInfo.booked > 0
        ? yellowColor // Yellow if partially booked
        : greenColor // Green if available
      : greenColor; // Default green if no slot info

    const handleClick = useCallback(() => {
      if (isFutureSlot && props.onSlotClick) {
        props.onSlotClick(); // Call the onSlotClick handler passed via props
      }
    }, [isFutureSlot, props]);

    return (
      <SchedulerSlot
        {...props}
        onClick={handleClick}
        style={{
          backgroundColor,
          cursor: isFutureSlot ? "pointer" : "default", // Pointer for future slots, default for past slots
          pointerEvents: isFutureSlot ? "auto" : "none", // Disable interaction for past slots
        }}
      >
        {props.children}
      </SchedulerSlot>
    );
  };

  //Hourly flow
  const [startTimeDilog, seStartTimeDilog] = useState("");
  const [endTimeDilog, setEndTimeDilog] = useState("");
  const [saveData, setSaveData] = useState(false);
  const hourlyRate = subTotal;
  const gstRate = taxPercentage; // GST 18%
  // const gstRate = 0.18; // GST 18%

  const hourlyEditProps = (props: SchedulerEditItemProps) => {
    if (props.dataItem.AppointmentID === 0) {
      seStartTimeDilog(`${props.start}`);
      setEndTimeDilog(`${props.end}`);

      let totalHours = 1;
      if (hourlyVariantAmount === 0) {
        const initialTime = getHours(props.start);
        const endTime = getHours(props.end);
        const initialMin = props.start.getMinutes();
        const endMin = props.end.getMinutes();

        totalHours = endTime - initialTime; // Calculate total hours
        let totalMinutes = 0; // To calculate the extra minutes into fractions of an hour

        const getFraction = (minutes: number) => {
          if (minutes > 0 && minutes <= 15)
            return 0.25; // 0-15 mins = 1/4th hour
          else if (minutes > 15 && minutes <= 30)
            return 0.5; // 16-30 mins = 1/2 hour
          else if (minutes > 30 && minutes <= 45)
            return 0.75; // 31-45 mins = 3/4th hour
          else if (minutes > 45) return 1; // 46-59 mins = full hour
          else return 0; // If 0 mins
        };

        // Calculate the fractional hours based on start and end minutes
        const startFraction = getFraction(initialMin);
        const endFraction = getFraction(endMin);

        totalMinutes += endFraction - startFraction; // Add/subtract fraction

        totalHours += totalMinutes; // Add fractional hours to total time
      }

      // Calculate base amount and GST
      const baseAmount = totalHours * hourlyRate;
      const gstAmount = (baseAmount * gstRate) / 100;
      const finalAmount = baseAmount + gstAmount;

      setBookingTotal(finalAmount); // Store the final amount
    }

    return (
      <div>
        <SchedulerEditItem
          {...props}
          title="Slot Booked"
          // title={`Start Time: ${getTime(startTimeDilog)} - End Time: ${getTime(
          //   endTimeDilog
          // )}`}
        />
      </div>
    );
  };

  const getHours = (e: any) => {
    const date = new Date(e);
    let hoursv1 = date.getHours();
    const padZero = (num: number) => (num < 10 ? `0${num}` : num);
    const minutesv1 = padZero(date.getMinutes());
    return parseInt(`${hoursv1}`);
  };

  // Calculation reverse login
  const calculateBaseAmount = (total: number): number => {
    const gstRate = taxPercentage; // GST rate in percentage
    const gstMultiplier = 1 + gstRate / 100; // 1.18 multiplier
    return Math.round(total / gstMultiplier);
  };

  const validationMessages = {
    aadhar: "Invalid Aadhar number.",
    pan: "Invalid PAN format.",
    gst: "Invalid GST format",
    license: "Invalid Driving License format.",
    cin: "Invalid Company CIN format.",
  };

  const patterns = {
    aadhar: /^[1-9][0-9]{11}$/,
    pan: /^[A-Z]{5}[0-9]{4}[A-Z]$/,
    gst: /^33[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
    license: /^TN[0-9]{2} ?[0-9]{4}[0-9]{6,7}$/,
    cin: /^[LU]{1}[0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
  };

  // Register flow
  const registerValidation = () => {
    const errors: RegisterFormErrors = {};
    const isCheckErrors: ErrorRegisterRequired = {};
    // Company Name validation
    if (selectedValue === "Company" && !roomBookFormData.CompanyName.trim()) {
      errors.companyNameErr = "Company name is required";
      isCheckErrors.companyNameErr = true;
    }

    // Name validation
    if (!roomBookFormData.customerName.trim()) {
      if (selectedValue === "Company") {
        errors.customerNameErr = "Contact person name is required";
        isCheckErrors.customerNameErr = true;
      } else {
        errors.customerNameErr = "Your name is required";
        isCheckErrors.customerNameErr = true;
      }
    }

    // Mobile number validation (10 digits)
    if (!roomBookFormData.contactNo.trim()) {
      errors.customerMobileErrr = "Mobile number is required";
      isCheckErrors.customerMobileErrr = true;
    } else if (!mobileRegex.test(roomBookFormData.contactNo)) {
      errors.customerMobileErrr = "Invalid mobile number and must be 10 digits";
      isCheckErrors.customerMobileErrr = true;
    }

    // Email validation
    if (!roomBookFormData.email.trim()) {
      errors.customerEmailErrr = "Email address is required";
      isCheckErrors.customerEmailErrr = true;
    } else if (!emailRegex.test(roomBookFormData.email)) {
      errors.customerEmailErrr = "Invalid email address";
      isCheckErrors.customerEmailErrr = true;
    }

    // Address validation
    if (!roomBookFormData.address.trim()) {
      if (selectedValue === "Company") {
        errors.customerAddres1Eerrr = "Company address 1 is required";
        isCheckErrors.customerAddres1Eerrr = true;
      } else {
        errors.customerAddres1Eerrr = "Address 1 is required";
        isCheckErrors.customerAddres1Eerrr = true;
      }
    }

    if (!roomBookFormData.address2.trim()) {
      if (selectedValue === "Company") {
        errors.customerAddres2Eerrr = "Company address 2 is required";
        isCheckErrors.customerAddres2Eerrr = true;
      } else {
        errors.customerAddres2Eerrr = "Address 2 is required";
        isCheckErrors.customerAddres2Eerrr = true;
      }
    }

    if (selectedCountryId === 0) {
      if (selectedValue === "Company") {
        errors.customerCountryErrr = "Company country is required";
        isCheckErrors.customerCountryErrr = true;
      } else {
        errors.customerCountryErrr = "Country is required";
        isCheckErrors.customerCountryErrr = true;
      }
    }
    if (selectedStateId === 0) {
      if (selectedValue === "Company") {
        errors.customerStateErrr = "Company state is required";
        isCheckErrors.customerStateErrr = true;
      } else {
        errors.customerStateErrr = "State is required";
        isCheckErrors.customerStateErrr = true;
      }
    }
    if (selectedCityId === 0) {
      if (selectedValue === "Company") {
        errors.customerCityErrr = "Company city is required";
        isCheckErrors.customerCityErrr = true;
      } else {
        errors.customerCityErrr = "City is required";
        isCheckErrors.customerCityErrr = true;
      }
    }
    // Pincode validation
    if (!roomBookFormData.pincode!.trim()) {
      if (selectedValue === "Company") {
        errors.customerPincodeErrr = "Company pincode is required";
        isCheckErrors.customerPincodeErrr = true;
      } else {
        errors.customerPincodeErrr = "Pincode is required";
        isCheckErrors.customerPincodeErrr = true;
      }
    }

    // KYC File upload validation
    if (!imageData.trim()) {
      errors.customerKYCDocumentErrr = "KYC Document is required ";
      isCheckErrors.customerKYCDocumentErrr = true;
    }

    // KYC Document upload validation
    if (
      selectedKYC.Name === "Select KYC Document" ||
      selectedKYCName === "Select KYC Document"
    ) {
      errors.customerKYCFileErrr = "KYC Document Type is required";
    }

    if (!roomBookFormData.kycNumber!.trim()) {
      errors.customerKycNumberErrr = `${selectedKYCName} is required`;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (
      !patterns.aadhar.test(roomBookFormData.kycNumber) &&
      selectedKYCID === 1
    ) {
      errors.customerKycNumberErrr = validationMessages.aadhar;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (
      !patterns.pan.test(roomBookFormData.kycNumber) &&
      selectedKYCID === 2
    ) {
      errors.customerKycNumberErrr = validationMessages.pan;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (
      !patterns.gst.test(roomBookFormData.kycNumber) &&
      selectedKYCID === 3
    ) {
      errors.customerKycNumberErrr = validationMessages.gst;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (
      !patterns.license.test(roomBookFormData.kycNumber) &&
      selectedKYCID === 4
    ) {
      errors.customerKycNumberErrr = validationMessages.license;
      isCheckErrors.customerKycNumberErrr = true;
    } else if (
      !patterns.cin.test(roomBookFormData.kycNumber) &&
      selectedKYCID === 5
    ) {
      errors.customerKycNumberErrr = validationMessages.cin;
      isCheckErrors.customerKycNumberErrr = true;
    }

    // Password validation
    if (!roomBookFormData.password.trim()) {
      errors.customerPasswordErrr = "Password is required";
      isCheckErrors.customerPassowordErrr = true;
    } else if (passwordValidationError !== "") {
      errors.customerPasswordErrr =
        "Password must be 8 digits and contain Uppercase, Lowercase, Special Characters, and numbers";
      isCheckErrors.customerPassowordErrr = true;
    }

    if (confirmPassword.trim() === "") {
      errors.customerConfirmPasswordErrr = "Confirm Password is required";
      isCheckErrors.customerConfirmPasswordErrr = true;
    } else if (roomBookFormData.password !== confirmPassword.trim()) {
      errors.customerConfirmPasswordErrr =
        "Confirm Password must be equal to password";
      isCheckErrors.customerConfirmPasswordErrr = true;
    }
    setRegisterRoomBookErrorFormData(errors);
    setIsCheckRegisterRequired(isCheckErrors);
    return Object.keys(errors).length === 0;
  };

  const registerUserAPI = async (e: any) => {
    e.preventDefault();
    const currentDate = new Date(); // Get the current date and time
    const isoString = currentDate.toISOString();

    if (registerValidation()) {
      setLoading(true);
      let registerData = {
        name: roomBookFormData.customerName,
        password: encryptedBase64Password(password),
        mobile: parseInt(roomBookFormData.contactNo),
        email: roomBookFormData.email,
        companyId: 1,
        roleId: 2,
        isActive: true,
        createdBy: 1,
        createdOn: isoString,
        address: roomBookFormData.address,
        address2: roomBookFormData.address2,
        country: countryName !== "" ? countryName : roomBookFormData.country,
        state: stateName !== "" ? stateName : roomBookFormData.state,
        city: cityName !== "" ? cityName : roomBookFormData.city,
        pincode: roomBookFormData.pincode,
        companyName: roomBookFormData.CompanyName,
        kycDocumentId: selectedKYCID,
        gstNumber: roomBookFormData.gst,
        notes: roomBookFormData.notes,
        kycDocument: "Yes",
        userType: true,
        customerType: selectedValue === "Company" ? 1 : 2,
        lastName: roomBookFormData.customerLastName,
        isVerified: false,
        countryId: selectedCountryId,
        stateId: selectedStateId,
        cityId: selectedCityId,
        KycDocumentNumber: roomBookFormData.kycNumber,
      };
      try {
        const response = await axios.post(
          `${baseURL}odata/Users`,
          registerData,
          {
            headers: header,
          }
        );

        if (response.status === 201) {
          uploadDocumentFiles(response.data.Id);
        } else {
          setVisible(true);
          setAlertContent("Server not rechable. Please try again later!");
          setLoading(false);
        }
      } catch (err: any) {
        if (err.response.status === 500) {
          setVisible(true);
          setAlertContent(`${err.response.data.detail}`);
          setLoading(false);
        }
      }
    }
  };

  // Upload file API
  const uploadDocumentFiles = async (bookingId: String) => {
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "User",
        docUploadRefNo: bookingId,
        docUploadRefSubType: "Space",
        image: [
          {
            value: imageData,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        handleRegister(roomBookFormData.email);
        closeDialogs(false);
        // setVisibleDialog(true);
        setVisibleDetails(false);
        setLoading(true);
        // resetRegisterDatas();
      } else {
        setVisible(true);
        setAlertContent("Server not rechable. Please try again later!");
        setLoading(false);
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    }
  };

  const resetRegisterDatas = () => {
    const errors: RegisterFormErrors = {};
    roomBookFormData.CompanyName = "";
    roomBookFormData.bookingTo = "";
    roomBookFormData.customerName = "";
    roomBookFormData.customerLastName = "";
    roomBookFormData.contactNo = "";
    roomBookFormData.email = "";
    roomBookFormData.address = "";
    roomBookFormData.address2 = "";
    roomBookFormData.country = "";
    roomBookFormData.state = "";
    roomBookFormData.city = "";
    roomBookFormData.kycNumber = "";
    roomBookFormData.pincode = "";
    roomBookFormData.gst = "";
    roomBookFormData.notes = "";
    roomBookFormData.password = "";
    setConfirmPassword("");
    setSelectedKYC(defaultKYCDicumentValue);
    setImageData("");
    setRegisterRoomBookErrorFormData(errors);
    setRoomBookFormData(roomBookFormData);
    setLoading(false);

    setLoginRoomBookErrorFormData({});
    setIsCheckLoginRequired({});
    setShowPassword(false);
    setShowConfirmPassword(false);
    getCountry();
  };

  // Clear hourly data & dialog in Hourly flow
  const resetHourlyCalculations = (resetValue: boolean) => {
    if (saveData === resetValue) {
      setActiveAppointment(null);
      setHourlyData([]);
      seStartTimeDilog("");
      setEndTimeDilog("");
      setsubTotal(0);
      setBookingTotal(0);
      setSelectedDuration(defaultDurationValue);
      reloadHourlyDaya();
    }
    setPickDuration(false);
  };

  const reloadHourlyDaya = () => {
    setHourlyData(hourlyDataOnce);
  };

  // login validation
  const handleLogin = () => {
    const errors: LoginFormErrors = {};
    const isCheckErrors: ErrorLoginRequired = {};

    if (roomBookLoginFormData?.bookingUserName.trim() == "") {
      errors.userNameErr = "Email Address is required";
      isCheckErrors.userNameErr = true;
    } else if (!emailRegex.test(roomBookLoginFormData.bookingUserName)) {
      errors.userNameErr = "Invalid email address";
      isCheckErrors.userNameErr = true;
    }
    if (roomBookLoginFormData?.bookingUserPassword.trim() == "") {
      errors.userPasswordErrr = "Password is required";
      isCheckErrors.userPasswordErrr = true;
    }
    setLoginRoomBookErrorFormData(errors);
    setIsCheckLoginRequired(isCheckErrors);
    return Object.keys(errors).length === 0;
  };

  const handleLoginInputChange = (e: any) => {
    const { name, value } = e.target;
    setRoomBookLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Login details API
  const loginAPI = async () => {
    if (handleLogin()) {
      setLoading(true);
      const makeid = (length: any) => {
        var result = "";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        return result;
      };

      const hashPassword = encryptedBcryptPassword(
        roomBookLoginFormData?.bookingUserPassword
      );
      const loginHeader = {
        "Content-Type": "application/json",
        accept: "application/json;odata.metadata=minimal;odata.streaming=true",
        "X-utt-jrwef": roomBookLoginFormData?.bookingUserName.valueOf(),
        "X-dfq-aghtr": `${hashPassword}`,
        "X-gff-rerdk": `${encryptedBcryptPassword(makeid(5))}`,
        "X-fgd-ghfff": `${encryptedBcryptPassword(makeid(5))}`,
      };

      apiAuthenticateLogin(baseURL, loginHeader)
        .then((response) => {
          const { code, type, message, userDetails } = response.data;
          if (
            response.status === 200 &&
            userDetails !== undefined &&
            response.data.userDetails.roleId === 2 &&
            response.data.userDetails.isVerified
          ) {
            if (response.data.type === "Success") {
              localStorage.setItem("userToken", response.data["tokenresponse"]);
              setLoading(false);
              saveUserDetails(response.data);
              getProfile();
              window.location.reload();
            } else if (response.data.code === 401) {
              setLoading(false);
              setVisibleDialog(false);
              setExistingOtpDialogOpen(true);
            } else if (response.data.userDetails.roleId === 1) {
              setLoading(false);
              setAlertContent(response.data.message);
            } else {
              setLoading(false);
              setVisible(true);
              setAlertContent(response.data.message);
            }
            setLoading(false);
          } else if (
            response.status === 200 &&
            response.data.code === 401 &&
            type === "Failed"
          ) {
            setLoading(false);
            setVisible(false);
            setExistingOtpDialogOpen(true);
            // setAlertContent("False");
          } else if (
            response.status === 200 &&
            response.data.code === 401 &&
            message === "Username or Password is incorrect" &&
            type === "UserFailed"
          ) {
            setLoading(false);
            setVisible(true);
            setExistingOtpDialogOpen(false);
            setAlertContent("Username or Password is incorrect");
          } else {
            setLoading(false);
            setVisible(true);
            setAlertContent("User Not Registered");
          }
        })
        .catch((error) => {
          // Log the full error object for better understanding of its structure
          // Show custom error message
          if (error.response) {
            // Handle specific API error messages
            const { status, code, message } = error.response.data || {};

            if (
              status === 200 &&
              code === 401 &&
              message === "Username or Password is incorrect"
            ) {
              setLoading(false);
              setVisibleDialog(false);
              setVisible(true);
              setExistingOtpDialogOpen(false);
              setAlertContent(message);
            } else {
              setLoading(false);
              setVisible(true);
              setAlertContent("User Not Registered");
            }
          } else {
            // Handle general errors that may not have response data
            setLoading(false);
            setVisible(true);
            setAlertContent("An unexpected error occurred. Please try again.");
          }
          setLoading(false);
        });
    }
  };

  // User local storage details
  const saveUserDetails = (data: any) => {
    localStorage.setItem("id", data["id"]);
    localStorage.setItem("customer_type", data["userDetails"]["customerType"]);
    localStorage.setItem("company_name", data["userDetails"]["companyName"]);
    localStorage.setItem("name", data["userDetails"]["name"]);
    localStorage.setItem("mobile", data["userDetails"]["mobile"]);
    localStorage.setItem("email", data["userDetails"]["email"]);
    // localStorage.setItem("address1", data["userDetails"]["address1"]);
    localStorage.setItem("address", data["userDetails"]["address"]);
    localStorage.setItem("customerId", data["userDetails"]["customerId"]);
    localStorage.setItem("countryId", data["userDetails"]["countryId"]);
    localStorage.setItem("stateId", data["userDetails"]["stateId"]);
    localStorage.setItem("cityId", data["userDetails"]["cityId"]);
    localStorage.setItem(
      "address2",
      data["userDetails"]["address2"] === null
        ? ""
        : data["userDetails"]["address2"]
    );
    localStorage.setItem(
      "country",
      data["userDetails"]["country"] === null
        ? ""
        : data["userDetails"]["country"]
    );
    localStorage.setItem(
      "state",
      data["userDetails"]["state"] === null ? "" : data["userDetails"]["state"]
    );
    localStorage.setItem(
      "city",
      data["userDetails"]["city"] === null ? "" : data["userDetails"]["city"]
    );
    localStorage.setItem("last_name", data["userDetails"]["lastName"]);
    localStorage.setItem(
      "pin_code",
      data["userDetails"]["pinCode"] === null
        ? ""
        : data["userDetails"]["pinCode"]
    );
    localStorage.setItem(
      "kyc_document_id",
      data["userDetails"]["kycDocumentId"]
    );
    localStorage.setItem(
      "kycDocumentNumber",
      data["userDetails"]["kycDocumentNumber"]
    );
    localStorage.setItem("gst_number", data["userDetails"]["gstNumber"]);
    localStorage.setItem("password", data["userDetails"]["password"]);
    localStorage.setItem("created", data["userDetails"]["createdOn"]);
    localStorage.setItem("token", data["tokenresponse"]);
    localStorage.setItem("customerID", data.userDetails.customerId);
    // storeUserDatas(data);
    closeDialogs(true);
    resetLoginDatas();
    setLoading(false);
    setLoggedIn(true);
  };

  const resetLoginDatas = () => {
    const errors: LoginFormErrors = {};
    roomBookLoginFormData.bookingUserName = "";
    roomBookLoginFormData.bookingUserPassword = "";
    setLoginRoomBookErrorFormData(errors);

    setRegisterRoomBookErrorFormData({});
    setIsCheckRegisterRequired({});
  };

  const removeUserDetails = () => {
    localStorage.removeItem("id");
    localStorage.removeItem("userToken");
    localStorage.removeItem("customer_type");
    localStorage.removeItem("company_name");
    localStorage.removeItem("name");
    localStorage.removeItem("mobile");
    localStorage.removeItem("email");
    // localStorage.removeItem("address1");
    localStorage.removeItem("address");
    localStorage.removeItem("customerId");
    localStorage.removeItem("countryId");
    localStorage.removeItem("stateId");
    localStorage.removeItem("cityId");
    localStorage.removeItem("address2");
    localStorage.removeItem("country");
    localStorage.removeItem("state");
    localStorage.removeItem("city");
    localStorage.removeItem("last_name");
    localStorage.removeItem("pin_code");
    localStorage.removeItem("kyc_document_id");
    localStorage.removeItem("kycDocumentNumber");
    localStorage.removeItem("gst_number");
    localStorage.removeItem("created");
    localStorage.removeItem("token");
    // removeUserDetails();
    setIsLoginSuccess(false);
    setLoggedIn(false);
  };

  // Otp verification

  const [otp, setOtp] = useState<string[]>(["", "", "", "", "", ""]); // Specify type as string[]
  const [isOtpDialogOpen, setOtpDialogOpen] = useState(false);
  const [isOtpSuccessDialogOpen, setOtpSuccessDialogOpen] = useState(false);
  const [isExistingOtpDialogOpen, setExistingOtpDialogOpen] = useState(false);
  const [
    isExistingOtpDialogVerificationOpen,
    setExistingOtpDialogVerificationOpen,
  ] = useState(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [resendMessage, setResendMessage] = useState<string | null>(null);
  const [otpError, setOtpError] = useState<string | null>(null);
  // const navigateotp = useNavigate();

  const handleRegister = async (email?: string) => {
    // Call API to send OTP
    const response = await fetch(`${baseURL}GenerateOtp `, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ emailId: email }),
    });

    if (response.ok) {
      setLoading(false);
      setOtpDialogOpen(true);
    } else {
      setLoading(false);
      setNotification("Failed to send OTP. Please try again.");
    }
  };

  const handleOtpChange = (index: number, value: string) => {
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setOtpError(null);

      // Automatically move to the next field if a digit is entered
      if (value && index < otp.length - 1) {
        (
          document.getElementById(`otp-${index + 1}`) as HTMLInputElement
        ).focus();
      }
    }
  };

  const handleOtpKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      // Move to the previous field if backspacing on an empty input
      (document.getElementById(`otp-${index - 1}`) as HTMLInputElement).focus();
    }
    // else if (event.key === "Enter") {
    //   handleOtpVerification();
    // }
  };

  const handleOtpVerification = async (email: string) => {
    const enteredOtp = otp.join(""); // Converts "706182" to the number 706182
    const enteredOtpNumber = parseInt(enteredOtp, 10);
    if (isNaN(enteredOtpNumber)) {
      setOtpError("Please enter OTP.");
      return;
    }
    setLoading(true);
    // Call API to verify OTP
    let data = {
      emailId: email,
      otp: enteredOtpNumber,
    };
    const response = await axios.post(`${baseURL}VerifyOtp`, data);
    // const verifyOTPResponseData = response.data;
    const { code, message, type } = response.data;
    if (response.status === 200 && type === "Success") {
      setLoading(false);
      setOtpDialogOpen(false);
      resetRegisterDatas();
      setOtpSuccessDialogOpen(true);
      setVisibleDialog(true);
      handleExistUserAlertClose();
    } else {
      setLoading(false);
      setOtpError("Entered OTP is not valid. Please enter the valid OTP.");
    }
  };

  const [timer, setTimer] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);

  useEffect(() => {
    if (isOtpDialogOpen) {
      setTimer(60); // Reset timer when dialog opens
      setIsResendEnabled(false); // Disable resend initially
    }
  }, [isOtpDialogOpen]);

  useEffect(() => {
    // Timer countdown logic
    if (timer > 0 && isOtpDialogOpen) {
      const countdown = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(countdown);
    } else if (timer === 0) {
      setIsResendEnabled(true); // Enable resend when timer reaches 0
    }
  }, [timer, isOtpDialogOpen]);

  // Resend OTP function
  const handleResendOtp = async () => {
    setLoading(true);
    try {
      await handleRegister(roomBookFormData.email); // Call your resend OTP API
      setLoading(false);
      setTimer(60); // Reset the timer after resending
      setIsResendEnabled(false); // Disable resend again
      setResendMessage("OTP has been Re-sent successfully");
      setTimeout(() => setResendMessage(null), 5000);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
    }
  };

  //
  const handleExisitingUserResendOtp = async () => {
    setExistingOtpDialogOpen(false);
    setLoading(true);
    try {
      await handleRegister(roomBookLoginFormData?.bookingUserName); // Call your resend OTP API
      setTimer(60); // Reset the timer after resending
      setIsResendEnabled(false); // Disable resend again
      setOtp(["", "", "", "", "", ""]);
      setExistingOtpDialogVerificationOpen(true);
      setLoading(false);
      setOtpDialogOpen(false);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
    }
  };
  const handleCloseDialog = () => {
    setOtp(["", "", "", "", "", ""]); // Clear OTP fields
    setOtpError(null); // Clear any error messages
    setOtpDialogOpen(false);
  };

  // Forget password

  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);
  const [forgotPasswordSuccessDialogOpen, setForgotPasswordSuccessDialogOpen] =
    useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");

  const handleForgotPasswordEmailChange = (e: any) => {
    const email = e.target.value;
    setForgotPasswordEmail(email);

    // Hide error message when user starts typing
    if (forgotPasswordError) {
      setForgotPasswordError("");
    }
  };

  const handleForgotPasswordDialogClose = () => {
    // Clear email and error state when dialog is closed
    setForgotPasswordEmail("");
    setForgotPasswordError("");
    setForgotPasswordDialogOpen(false);
  };

  const handleForgotPassword = async () => {
    if (!forgotPasswordEmail.trim()) {
      setForgotPasswordError("Email should not be empty.");
      return;
    }
    if (!emailRegex.test(forgotPasswordEmail)) {
      setForgotPasswordError("Please enter a valid email address.");
      return;
    }
    setLoading(true);
    setForgotPasswordEmail("");
    setForgotPasswordError("");
    // Call the Forgot Password API

    // try {
    //   setLoading(true);

    //   const response = await axios.post(
    //     `${baseURL}odata/ForgotPassword?email=${forgotPasswordEmail}`,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     }
    //   );

    //   if (response.status === 200) {
    //     setLoading(false);
    //     setForgotPasswordDialogOpen(false);
    //     setForgotPasswordSuccessDialogOpen(true);
    //   } else if (
    //     response.status === 500 &&
    //     response.data.detail === "User Not Found"
    //   ) {
    //     setLoading(false);
    //     setForgotPasswordError("User not found.");
    //   } else {
    //     setLoading(false);
    //     setForgotPasswordError(
    //       "Failed to send recovery email. Please try again."
    //     );
    //   }
    // } .catch((error: any) => {
    //   setLoading(false);

    //   if (error.response && error.response.data) {
    //     const { code, message } = error.response.data;

    //     if (code === 500 && message) {
    //       setForgotPasswordError("User not found.");
    //     } else {
    //       console.error("Error sending forgot password request:", error);
    //       setForgotPasswordError("An error occurred. Please try again later.");
    //     }
    //   } else {
    //     console.error("Error sending forgot password request:", error);
    //     setForgotPasswordError("An error occurred. Please try again later.");
    //   }
    // });
    try {
      setLoading(true);

      const response = await axios.post(
        `${baseURL}odata/ForgotPassword?email=${forgotPasswordEmail}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setLoading(false);
        setForgotPasswordDialogOpen(false);
        setForgotPasswordSuccessDialogOpen(true);
      } else if (
        response.status === 500 &&
        response.data.detail === "User Not Found"
      ) {
        setLoading(false);
        setForgotPasswordError("User not found.");
      } else {
        setLoading(false);
        setForgotPasswordError(
          "Failed to send recovery email. Please try again."
        );
      }
    } catch (error: any) {
      setLoading(false);

      if (error.response && error.response.data) {
        const { title, status, detail } = error.response.data;

        // Check for specific error structure and message
        if (status === 500 && detail === "User Not Found") {
          setForgotPasswordError("User not found.");
        } else {
          console.error("Error sending forgot password request:", error);
          setForgotPasswordError("An error occurred. Please try again later.");
        }
      } else {
        console.error("Error sending forgot password request:", error);
        setForgotPasswordError("An error occurred. Please try again later.");
      }
    }
  };

  const bookNow = (isNavigate: any) => {
    if (isLoginSuccess) {
      if (isNavigate === true) {
        setInputValue("");
        setErrorInputValue("");
        setIsBillDetails(true);
        getDiscountList(); // KYC Document API Call

        window.setTimeout(() => {
          window.scroll({
            top: 1600,
            left: 0,
            behavior: "smooth",
          });
        }, 5);
      } else {
        setLogoutDialog(true);
      }
    } else {
      setVisibleDialog(true);
    }
  };

  // Enter key
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      loginAPI(); // Trigger login when Enter key is pressed
    }
  };

  const handleKeyDownRegister = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      registerUserAPI(e); // Trigger login when Enter key is pressed
    }
  };

  // Close login
  const closeDialogs = (successResult: boolean) => {
    setIsLoginSuccess(successResult); // Login dialog disable
    setIsBillDetails(false);
    setVisibleDialog(false);
    setMobileError("");
    setPasswordError("");
  };

  // Toggle login status
  const handleLoginLogout = () => {
    bookNow(false);
    setShowPopup(false); // Close the popup after clicking
  };

  // Toggle popup visibility
  const handleProfileClick = () => {
    setShowPopup(!showPopup);
  };

  const logoutHandler = (isLogout: any) => {
    if (isLogout === true) {
      removeUserDetails();
    }
    setLogoutDialog(false);
    window.location.reload();
  };

  const closeDialog = () => {
    setVisibleDetails(false);
    setVisibleDialog(false);
    resetLoginDatas();
    resetRegisterDatas();
    setRoomBookErrorFormData({});
    setIsCheckRequired({});
    setRegisterRoomBookErrorFormData({});
    setIsCheckRegisterRequired({});
    setLoginRoomBookErrorFormData({});
    setIsCheckLoginRequired({});
    setShowPassword(false);
    setShowConfirmPassword(false);
  };

  // Close popup if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // Check if the click is outside both the popup and the anchor (profile image)
      const path = event.composedPath ? event.composedPath() : event.path;
      if (
        !path.includes(popupRef.current) &&
        !path.includes(anchorRef.current)
      ) {
        setShowPopup(false);
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when component unmounts or showPopup changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopup]);

  const headerFormatDate = (date: Date): string => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Get day of the week, day of the month, month, and year
    const day = String(date.getDate()).padStart(2, "0"); // Adds leading zero if necessary (e.g., "18")
    const month = months[date.getMonth()]; // E.g., "Sep"
    const year = date.getFullYear(); // E.g., "2024"

    // Concatenate in the format "EEE, dd-MMM-yyyy"
    return `${day}-${month}-${year}`;
  };

  const CustomDateHeaderCell = (props: any) => {
    const formattedDate = headerFormatDate(props.date);
    return <div className="hourly-header-label">{formattedDate}</div>;
  };

  // Restrict scroll & keyboart action in date picker
  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;
  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener
      element.addEventListener("wheel", handleWheel, { passive: false });

      // Clean up event listener
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle between password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const navigateNextScreen = () => {
    navigate("/bookings");
  };

  const navigateMyOrders = () => {
    navigate("/myorders");
  };

  const navigateMyProfile = () => {
    navigate("/myprofile");
  };

  const [showDialog, setShowDialog] = useState(false);
  const [popupSpaceDetails, setPopupSpaceDetails] = useState<SpaceDetails[]>();
  const [discountTypeId, setDiscountTypeId] = useState(0);

  const spaceDetailsnDialog = (SpaceData: SpaceDetails[]) => {
    setPopupSpaceDetails(SpaceData);
    setShowDialog((prev) => !prev);
  };

  const handleHidePopup = () => {
    setShowDialog(false);
  };

  // Coupon flow
  const getDiscountList = async () => {
    try {
      const response = await axios.get(
        `${baseURL}ApplyDiscountSummary?date=${formatDate(
          checkInDate
        )}&userid=${localStorage.getItem("id")}&spaceid=${
          spaceData.Id
        }&durationid=${selectedDurationID}`
      );
      const responseData = response.data;
      const filteredData = responseData.filter(
        (item: any) => item.discountValue !== 0
      );
      setDiscountList(filteredData);
    } catch (err) {
      setDiscountList([]);
    }
  };

  const handleDiscountSelect = () => {
    if (selectedIndex !== null) {
      setDiscounAmount(discountList[selectedIndex]["discountValue"]);
      setDiscountTypeId(discountList[selectedIndex]["discountType"]);
      setDiscountId(discountList[selectedIndex]["discountId"]);
      setDiscountName(discountList[selectedIndex]["discountName"]);
      setDiscountDescription(
        discountList[selectedIndex]["discountDescription"]
      );
    }
    setCouponDialog(false);
  };

  const handleRemoveDiscount = () => {
    setDiscountName("");
    setDiscountDescription("");
    setDiscountTypeId(0);
    setDiscounAmount(0);
    setSelectedIndex(null);
    setCouponDialog(false);
    setDiscountId(0);
  };

  const discountFixedCalculation = () => {
    return Math.round(discountAmount);
  };

  const discountPercentageCalculation = () => {
    return (calculateBaseAmount(bookingTotal) * discountAmount) / 100;
  };

  const taxFixedCalculation = () => {
    return Math.round(
      ((calculateBaseAmount(bookingTotal) - discountAmount) * taxPercentage) /
        100
    );
  };

  const taxPercentageCalculation = () => {
    const taxCalculation =
      (calculateBaseAmount(bookingTotal) * discountAmount) / 100;
    const discountCalculation =
      calculateBaseAmount(bookingTotal) - taxCalculation;
    return (discountCalculation * taxPercentage) / 100;
  };

  const totalFixedCalculation = () => {
    return Math.round(
      ((calculateBaseAmount(bookingTotal) - discountAmount) * taxPercentage) /
        100 +
        (calculateBaseAmount(bookingTotal) - discountAmount) +
        depositAmount
    );
  };

  const totalPercentageCalculation = () => {
    const taxCalculation =
      (calculateBaseAmount(bookingTotal) * discountAmount) / 100;
    const discountCalculation =
      calculateBaseAmount(bookingTotal) - taxCalculation;
    const finalTax = (discountCalculation * taxPercentage) / 100;
    return Math.round(finalTax + discountCalculation + depositAmount);
  };

  const handleExistUserAlert = () => {
    setLoading(true);
    setOtp(["", "", "", "", "", ""]); // Clear OTP fields
    // setExistingOtpDialogVerificationOpen(true);
    handleExisitingUserAlert();
    setOtpError(null);
    setExistingOtpDialogOpen(false);
    // resetLoginDatas();
  };

  const handleExisitingUserAlert = () => {
    setLoading(false);
    setExistingOtpDialogVerificationOpen(true);
    // resetLoginDatas();
  };

  const handleExistUserAlertClose = () => {
    setOtp(["", "", "", "", "", ""]); // Clear OTP fields
    setOtpError(null);
    setExistingOtpDialogVerificationOpen(false);
    resetLoginDatas();
  };

  const handleOtpSuccessAlertClose = () => {
    setOtpSuccessDialogOpen(false);
    setVisibleDialog(true);
    resetLoginDatas();
  };

  const handleExistUserCloseAlert = () => {
    resetLoginDatas();
    setExistingOtpDialogOpen(false);
  };

  // Login dialog visible by session timeout
  const [timerKey, setTimerKey] = useState(0); // To reset timer

  const handleOpenLoginDialog = () => setVisibleDialog(true);

  const [inputValue, setInputValue] = useState("");
  const [errorInputValue, setErrorInputValue] = useState("");
  const [showApplyButton, setShowApplyButton] = useState(false);

  // Handle input change
  const handleInputChangeApply = (event: any) => {
    const value = event.target.value;
    setInputValue(value);
    setShowApplyButton(value.trim() !== "");
  };

  // Handle apply button click
  const handleApplyClick = () => {
    setCouponDialog(true); // Replace with your logic
  };

  const applyManualCoupon = async (e: any) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}ApplyInstantDiscounts?couponCode=${inputValue}&spaceId=${
          spaceData.Id
        }&durationId=${selectedDurationID}&userId=${localStorage.getItem("id")}`
      );
      if (response.data.type !== "Failed") {
        if (response.data.content.length > 0) {
          setDiscounAmount(response.data.content[0]["discountValue"]);
          setDiscountTypeId(response.data.content[0]["discountType"]);
          setDiscountId(response.data.content[0]["discountId"]);
          setDiscountName(response.data.content[0]["discountName"]);
          setDiscountDescription(
            response.data.content[0]["discountDescription"]
          );
          setLoading(false);
          setInputValue("");
          setErrorInputValue("");
          setShowApplyButton(false);
        } else {
          setLoading(false);
          setErrorInputValue(
            "Coupon code not found. Please try another coupon code"
          );
        }
      } else {
        setLoading(false);
        setErrorInputValue(response.data.message);
      }
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  return (
    <>
      <div>
        <AppBar className="header-row">
          <AppBarSection>
            <img
              src={require("./assets/images/logo.png")}
              alt="Logo"
              style={{ height: "40px", marginRight: "20px" }}
            />
          </AppBarSection>
          <AppBarSection className="menu-alignment">
            <Label className="top-view top-selected">Book a Space</Label>
          </AppBarSection>
          <AppBarSpacer style={{ flexGrow: 1 }} />
          <AppBarSection className="top-view">
            {profileImage === "" ? (
              <img
                ref={anchorRef} // Reference for positioning the popup
                src={require("./assets/images/profile.png")}
                alt="Profile"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleProfileClick} // Toggle popup on click
              />
            ) : (
              <img
                ref={anchorRef} // Reference for positioning the popup
                src={profileImage}
                alt="Profile"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                onClick={handleProfileClick} // Toggle popup on click
              />
            )}
            <Popup
              anchor={anchorRef.current} // Attach popup to profile image
              collision={{
                horizontal: "fit",
                vertical: "flip",
              }}
              show={showPopup}
              popupClass={"popup-content"}
              animate={false}
            >
              <div ref={popupRef} style={{ padding: "10px" }}>
                {loggedIn && (
                  <div>
                    <Label
                      style={{ color: "#42B677" }}
                    >{`Welcome ${localStorage.getItem("name")}`}</Label>
                  </div>
                )}
                {loggedIn && (
                  <div>
                    <Button
                      fillMode="link"
                      className="popup-button-block"
                      onClick={navigateNextScreen}
                    >
                      My Bookings
                    </Button>
                    <Button
                      fillMode="link"
                      className="popup-button-block"
                      onClick={navigateMyOrders}
                    >
                      My Orders
                    </Button>
                    <Button
                      fillMode="link"
                      className="popup-button-block"
                      onClick={navigateMyProfile}
                    >
                      My Profile
                    </Button>
                  </div>
                )}
                <Button fillMode="link" onClick={handleLoginLogout}>
                  {loggedIn ? "Logout" : "Login"}
                </Button>
              </div>
            </Popup>
          </AppBarSection>
        </AppBar>
        {loading && (
          <div className="loading-screen">
            <Loader size="large" type={"infinite-spinner"} />
          </div>
        )}
        {logoutDialog && (
          <Dialog
            onClose={() => setVisibleDialog(false)}
            className="dialog-container"
          >
            <div className="dialog-content">
              <Label className="dialog-heading">Alert Dialog</Label>
              <Label>Are you sure you want to logout?</Label>
              <div className="login-button-content">
                <Button
                  className="login-button-dialog"
                  onClick={(e) => logoutHandler(true)}
                >
                  Yes
                </Button>
                <Button
                  className="login-button-dialog"
                  onClick={(e) => logoutHandler(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        {!loading && (
          <div className="body top-space">
            <div className="w-3/4 m-auto">
              <div className="mt-20">
                {spaceDetailsData.length > 0 ? (
                  <Slider slidesPerPageSettings={slidesPerPageSettings}>
                    {spaceDetailsData !== undefined &&
                      spaceDetailsData.map((card: rooms, index) =>
                        selectedCard === index ? (
                          <div
                            key={index}
                            className="boxshadow-enable"
                            style={{
                              width: "300px",
                              backgroundColor: "#f8f8f8",
                              borderRadius: "20px",
                              margin: "20px",
                              boxShadow: "0 0 10px rgba(66,182,119,255)",
                            }}
                          >
                            <div style={{ padding: "20px" }}>
                              <img
                                style={{
                                  height: "250px",
                                  width: "100%",
                                  borderRadius: "20px",
                                }}
                                src={`${imageURL}${card.SpaceImage}`}
                              />
                              <div style={{ height: "130px" }}>
                                {/* <div> */}
                                <div>
                                  <CardTitle className="heading-style">
                                    {card.SpaceName}
                                  </CardTitle>
                                </div>
                                {card.SpaceDetails.slice(0, 2).map(
                                  (space, index) => (
                                    <div className="subHeading" key={index}>
                                      {space.Duration.DurationType}: ₹{" "}
                                      {space.RentAmount}
                                    </div>
                                  )
                                )}

                                {card.SpaceDetails.length >= 3 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        spaceDetailsnDialog(card.SpaceDetails)
                                      }
                                    >
                                      ...View more
                                    </a>
                                  </div>
                                )}
                                {/* {card.SpaceDetails.map(
                                  (roomSpace: SpaceDetails) => {
                                    return (
                                      <div className="subHeading">
                                        {roomSpace.DurationName} : ₹{" "}
                                        {roomSpace.RentAmount}
                                      </div>
                                    );
                                  }
                                )} */}
                              </div>
                              <div style={{ paddingTop: "10px" }}>
                                <Button
                                  className="button-style"
                                  type="button"
                                  svgIcon={checkCircleIcon}
                                  onClick={() => {
                                    // handleSpaceSelect(index);
                                  }}
                                >
                                  {selectedCard === index
                                    ? "Selected"
                                    : "Select"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            key={index}
                            className="boxshadow-disable"
                            style={{
                              width: "300px",
                              backgroundColor: "#f8f8f8",
                              borderRadius: "20px",
                              margin: "20px",
                            }}
                          >
                            <div style={{ padding: "20px" }}>
                              <img
                                style={{
                                  height: "250px",
                                  width: "100%",
                                  borderRadius: "20px",
                                }}
                                src={`${imageURL}${card.SpaceImage}`}
                              />
                              <div style={{ height: "130px" }}>
                                {/* <div> */}
                                <div>
                                  <CardTitle className="heading-style">
                                    {card.SpaceName}
                                  </CardTitle>
                                </div>
                                {card.SpaceDetails.slice(0, 2).map(
                                  (space, index) => (
                                    <div className="subHeading" key={index}>
                                      {space.Duration.DurationType}: ₹{" "}
                                      {space.RentAmount}
                                    </div>
                                  )
                                )}

                                {card.SpaceDetails.length >= 3 && (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        spaceDetailsnDialog(card.SpaceDetails)
                                      }
                                    >
                                      ...View more
                                    </a>
                                  </div>
                                )}
                                {/* {card.SpaceDetails.map(
                                  (roomSpace: SpaceDetails) => {
                                    return (
                                      <div className="subHeading">
                                        {roomSpace.DurationName} : ₹{" "}
                                        {roomSpace.RentAmount}
                                      </div>
                                    );
                                  }
                                )} */}
                              </div>
                              <div style={{ paddingTop: "10px" }}>
                                <Button
                                  className="button-style"
                                  style={{}}
                                  type="button"
                                  onClick={() => {
                                    resetHourlyCalculations(false);
                                    handleSpaceSelect(index);
                                    // getSpaceDetails(index);
                                    setSelectedImage(card.SpaceImage);
                                    setSelectedImageDescription(
                                      card.Description
                                    );
                                    setSelectedSpaces(card.SpaceName);
                                    setSelectedSeats(card.NoOfSeats);
                                  }}
                                >
                                  {selectedCard === index
                                    ? "Selected"
                                    : "Select"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                  </Slider>
                ) : (
                  <div style={{ textAlign: "center" }}>
                    No Space data available
                  </div>
                )}
              </div>
            </div>
            {pickDuration === true ? (
              <div
                style={{
                  width: "50%",

                  height: "120px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <Dialog>
                  <div className="common-space hourlt-scheduler">
                    <Scheduler
                      editable={{
                        add: !activeAppointment,
                        remove: true,
                        drag: false,
                        resize: false,
                        select: false,
                        edit: true,
                      }}
                      defaultDate={checkInDate!}
                      modelFields={hourlyBookingModelFields}
                      data={hourlyData}
                      onDataChange={(e) => {
                        handleDataChange(e);
                      }}
                      editItem={hourlyEditProps}
                      form={FormWithCustomEditor}
                      slot={(props) => {
                        if (props.isAllDay) {
                          return null;
                        }
                        return <HourlySlot {...props} />;
                      }}
                    >
                      <DayView
                        dateHeaderCell={CustomDateHeaderCell} // Use custom date header
                        showWorkHours={false}
                        slotDivisions={4}
                        // step={15}
                        startTime={hourlyOpenTime}
                        endTime={hourlyCloseTime}
                      />
                    </Scheduler>
                    <div>
                      <Label className="manditory-fields">
                        Note: Please double-click on the green timeline to book
                        your slot.
                      </Label>
                    </div>
                    {Number(hourlyVariantAmount) !== 0 && (
                      <Label className="manditory-fields">
                        Note: User can book maximum {hourlyVariantName} and cost
                        collected Rs. {Number(hourlyVariantAmount)}
                      </Label>
                    )}
                    <div
                      style={{
                        padding: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "end",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setPickDuration(false);
                          setSaveData(true);
                        }}
                        disabled={activeAppointment === null ? true : false}
                        style={{
                          marginRight: "10px",
                          height: "35px",
                          padding: "20px 30px 20px 30px",

                          backgroundColor: "#3AB54A",
                          color: "white",
                          fontWeight: "600",
                          borderRadius: 20,
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setIsBillDetails(false);
                          resetHourlyCalculations(false);
                        }}
                        style={{
                          height: "35px",
                          padding: "20px 30px 20px 30px",
                          backgroundColor: "white",
                          color: "orange",
                          display: "flex",
                          alignItems: "center",
                          boxShadow: "0 0px 0px rgba(0, 0, 0, 0.1)",
                          borderColor: "white",
                          fontWeight: "600",
                          borderRadius: 20,
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </div>
            ) : (
              <div></div>
            )}
            {/* Selected space , Scneduler & Fields */}
            {selectDateScreen === true ? (
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="form-content-shedular-accordian">
                  <Label
                    style={{
                      paddingTop: "0px",
                      display: "inline-flex",
                      fontSize: " 1.4rem",
                      fontWeight: "400",
                      marginLeft: "25px",
                    }}
                  >
                    Selected Space
                  </Label>
                  <div
                    style={{
                      marginTop: "10px",
                      backgroundColor: "#f8f8f8",
                      borderRadius: "20px",
                      boxShadow: "0 0 10px rgba(66,182,119,255)",
                      marginBottom: "50px",
                    }}
                  >
                    <div style={{}}>
                      <div className="select-booking">
                        <img
                          className="image-spaces"
                          style={{
                            height: "200px",
                            width: "250px",
                            borderRadius: "20px",
                          }}
                          src={
                            selectImage !== ""
                              ? `${imageURL}${selectImage}`
                              : ""
                          }
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "start",
                            flexDirection: "column",
                          }}
                          className="form-content-shedular-accordian-inside"
                        >
                          <Label
                            className="heading-size"
                            style={{
                              paddingTop: "0px",
                              display: "inline-flex",
                            }}
                          >
                            {selectedSpaces}
                          </Label>

                          <div className="low-bottom-padding-she">
                            <Label className="description-text">
                              Description
                            </Label>
                            <p className="description-size-sche">
                              {selectImageDescription}
                            </p>
                          </div>
                          {/* <div className="low-bottom-padding-she">
                      <Label className="description-text">
                        Package Details
                      </Label>
                      {inclusionPackageDetails !== "" && (
                        <p>{`Inclusion : ${inclusionPackageDetails}`}</p>
                      )}
                      {exclusionPackageDetails !== "" &&
                        exclusionPackageDetails !== "string" && (
                          <p>{`Exclusion : ${exclusionPackageDetails}`}</p>
                        )}
                    </div> */}
                        </div>
                      </div>

                      {/* <PanelBarItem expanded={true}>sfsafsa</PanelBarItem> */}
                    </div>
                  </div>

                  <div className="shedular-alignment-flex">
                    {/* Scheduler view */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Scheduler
                        height={"100%"}
                        style={{ width: "100%", height: "100%" }}
                        editable={false}
                        onDateChange={handleDateChange}
                        slot={CustomSlot}
                      >
                        <MonthView />
                      </Scheduler>

                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <div
                            style={{
                              padding: "10px",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                height: "30px",
                                width: " 30px",
                                backgroundColor: greenColor,
                              }}
                            ></div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                paddingLeft: "20px",
                              }}
                            >
                              Available{" "}
                            </div>
                          </div>

                          <div
                            style={{
                              padding: "10px",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                height: "30px",
                                width: " 30px",
                                backgroundColor: yellowColor,
                              }}
                            ></div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                paddingLeft: "20px",
                              }}
                            >
                              Partially Booked{" "}
                            </div>
                          </div>

                          <div
                            style={{
                              padding: "10px",
                              flexDirection: "row",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                height: "30px",
                                width: " 30px",
                                backgroundColor: redColor,
                              }}
                            ></div>
                            <div
                              style={{
                                alignItems: "center",
                                display: "flex",
                                paddingLeft: "20px",
                              }}
                            >
                              Booked{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* Right side fields */}
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="">
                          <div className="form-group common-space">
                            <Label>
                              Check-in Date
                              <Label className="manditory-fields">*</Label>
                            </Label>
                            <div>
                              {/* <div style={{ width: "350px" }}> */}
                              <MyDatePicker
                                minDate={new Date()}
                                checkInDate={checkInDate}
                                isDisableCheck={true}
                                customFormatter={false}
                                setCheckInDate={(e: any) => {
                                  setCheckInDate(e);
                                  setTimeCalc(e);
                                }}
                              />
                            </div>
                            <div>
                              {(checkInDate === undefined ||
                                checkInDate === null) &&
                                roomBookErrorFormData?.spaceDateErr && (
                                  <Label className="error-field">
                                    {roomBookErrorFormData?.spaceDateErr}
                                  </Label>
                                )}
                            </div>
                          </div>
                          <div className="form-group common-space">
                            <Label>
                              Select Duration
                              <Label className="manditory-fields">*</Label>
                            </Label>
                            <DropDownList
                              style={{ width: "350px" }}
                              className="personal-fields"
                              textField="DurationName"
                              dataItemKey="Id"
                              data={durationDetailsData}
                              value={selectedDuration}
                              disabled={!checkInDate}
                              onChange={handleDropdownChange}
                            />
                            <div>
                              {(selectedDuration.DurationName ===
                                "Select Duration" ||
                                selectedDurationName === "Select Duration") &&
                                roomBookErrorFormData?.spaceDurationErr && (
                                  <Label className="error-field">
                                    {roomBookErrorFormData?.spaceDurationErr}
                                  </Label>
                                )}
                            </div>
                            {/* {isCheckDay === false && (
                      <Label className="valid-day-fields">
                        Booking is not allowed after 11:00 AM
                      </Label>
                    )} */}
                          </div>

                          {startTimeDilog !== "" && (
                            <div>
                              <div className="form-group common-space">
                                <Label>Start Time</Label>
                                <div>
                                  {/* <div style={{ width: "350px" }}> */}
                                  <MyDatePicker
                                    minDate={checkInDate!}
                                    checkInDate={new Date(startTimeDilog)}
                                    isDisableCheck={true}
                                    customFormatter={true}
                                    setCheckInDate={(e: any) => {
                                      calculateDifferenceDates(checkInDate, e);
                                      setCheckOutDate(e);
                                      setTimeCalc(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="form-group common-space">
                                <Label>End Time</Label>
                                <div>
                                  {/* <div style={{ width: "350px" }}> */}
                                  <MyDatePicker
                                    minDate={checkInDate!}
                                    checkInDate={new Date(endTimeDilog)}
                                    isDisableCheck={true}
                                    customFormatter={true}
                                    setCheckInDate={(e: any) => {
                                      calculateDifferenceDates(checkInDate, e);
                                      setCheckOutDate(e);
                                      setTimeCalc(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {isCheckOutDaily === false && (
                            <div className="form-group common-space">
                              <Label>
                                Check-out Date
                                <Label className="manditory-fields">*</Label>
                              </Label>
                              <div>
                                {/* <div style={{ width: "350px" }}> */}
                                <style>{styles}</style>
                                <div className="no-wheel" ref={timePickerRef}>
                                  <MyDatePicker
                                    minDate={checkInDate!}
                                    checkInDate={checkOutDate}
                                    isDisableCheck={false}
                                    customFormatter={false}
                                    setCheckInDate={(e: any) => {
                                      calculateMonthSeats(
                                        selectedSeatsCount,
                                        calculateDifferenceDates(checkInDate, e)
                                      );
                                      // calculateDifferenceDates(checkInDate, e);
                                      setCheckOutDate(e);
                                      setTimeCalc(e);
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {(checkInDate === undefined ||
                                  checkInDate === null) &&
                                  roomBookErrorFormData?.spaceDateErr && (
                                    <Label className="error-field">
                                      {roomBookErrorFormData?.spaceDateErr}
                                    </Label>
                                  )}
                              </div>
                            </div>
                          )}
                          {isCheckMonth === false && (
                            <div className="form-group common-space">
                              <Label>No. of months?</Label>
                              <DropDownList
                                style={{ width: "350px" }}
                                className="personal-fields"
                                name="month"
                                data={monthCount}
                                value={selectedMonthCount}
                                onChange={handleDropdownChange}
                              />
                            </div>
                          )}
                          {isCheckSpaceCount === false &&
                            selectedDuration.DurationName !==
                              "Select Duration" && (
                              <div className="form-group common-space">
                                <Label>
                                  No. of seats required?
                                  <Label className="manditory-fields"></Label>
                                </Label>
                                <DropDownList
                                  style={{ width: "350px" }}
                                  className="personal-fields"
                                  name="seats"
                                  data={seatsCount}
                                  value={selectedSeatsCount}
                                  onChange={handleDropdownChange}
                                />
                                <div>
                                  {(selectedDuration.DurationName ===
                                    "Select Duration" ||
                                    selectedDurationName ===
                                      "Select Duration") &&
                                    roomBookErrorFormData?.spaceDurationErr && (
                                      <Label className="error-field">
                                        {
                                          roomBookErrorFormData?.spaceDurationErr
                                        }
                                      </Label>
                                    )}
                                </div>
                              </div>
                            )}
                          {isCheckHourly === false && (
                            <div>
                              <div className="form-group common-space">
                                <Label>From Time</Label>
                                <DropDownList
                                  style={{ width: "350px" }}
                                  className="personal-fields"
                                  data={timeList}
                                  name="bookingFrom"
                                  value={selectedFromTime}
                                  onChange={(e) => {
                                    handleDropdownChange(e);
                                    settoTimeEnable(false);
                                  }}
                                />
                                <div>
                                  {(roomBookFormData?.bookingFrom === "" ||
                                    roomBookFormData?.bookingFrom ===
                                      "Select From Time" ||
                                    selectedFromTime === "Select From Time") &&
                                    roomBookErrorFormData?.bookingFromErrr && (
                                      <Label className="error-field">
                                        {roomBookErrorFormData?.bookingFromErrr}
                                      </Label>
                                    )}
                                </div>
                              </div>
                              <div className="form-group common-space">
                                <Label>To Time</Label>
                                <DropDownList
                                  style={{ width: "350px" }}
                                  className="personal-fields"
                                  data={getFilteredToTimeList()}
                                  name="bookingTo"
                                  value={selectedToTime}
                                  disabled={totimeEnable}
                                  onChange={handleToTimeChange}
                                />
                                <div>
                                  {(roomBookFormData?.bookingTo === "" ||
                                    roomBookFormData?.bookingTo ===
                                      "Select To Time" ||
                                    selectedToTime === "Select To Time") &&
                                    roomBookErrorFormData?.bookingToErrr && (
                                      <Label className="error-field">
                                        {roomBookErrorFormData?.bookingToErrr}
                                      </Label>
                                    )}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="containerStyle">
                            <span className="priceStyle">
                              Rs. {Math.round(bookingTotal)}
                            </span>
                            <span className="baseRateStyle">
                              Base rate: {subTotal} / {selectedDurationName}
                            </span>
                          </div>
                          <div className="button-view">
                            <Button
                              disabled={
                                bookingTotal === 0 ||
                                (Number(selectedDurationTypeID) === 2 &&
                                  checkOutDate === null)
                                  ? true
                                  : false
                              }
                              className="button-style"
                              onClick={(e) => bookNow(true)}
                            >
                              Book Now
                            </Button>
                          </div>
                        </div>
                        {/* {isCheckHourly === false && (
                      <div className="form-row">
                        <div className="form-group common-space">
                          <Label>From Time</Label>
                          <DropDownList
                            style={{ width: "300px" }}
                            className="personal-fields"
                            data={timeList}
                            name="bookingFrom"
                            value={selectedFromTime}
                            onChange={(e) => {
                              handleDropdownChange(e);
                              settoTimeEnable(false);
                            }}
                          />
                          <div>
                            {(roomBookFormData?.bookingFrom === "" ||
                              roomBookFormData?.bookingFrom ===
                                "Select From Time" ||
                              selectedFromTime === "Select From Time") &&
                              roomBookErrorFormData?.bookingFromErrr && (
                                <Label className="error-field">
                                  {roomBookErrorFormData?.bookingFromErrr}
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="form-group common-space">
                          <Label>To Time</Label>
                          <DropDownList
                            style={{ width: "300px" }}
                            className="personal-fields"
                            data={getFilteredToTimeList()}
                            name="bookingTo"
                            value={selectedToTime}
                            disabled={totimeEnable}
                            onChange={handleToTimeChange}
                          />
                          <div>
                            {(roomBookFormData?.bookingTo === "" ||
                              roomBookFormData?.bookingTo ===
                                "Select To Time" ||
                              selectedToTime === "Select To Time") &&
                              roomBookErrorFormData?.bookingToErrr && (
                                <Label className="error-field">
                                  {roomBookErrorFormData?.bookingToErrr}
                                </Label>
                              )}
                          </div>
                        </div>
                      </div>
                    )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {/* Billing Details */}
            {isLoginSuccess === true && isBillDetails === true ? (
              <div>
                {" "}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      width: " 95%",
                    }}
                  >
                    <h2 className="bill-details-header">Bill Details</h2>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div className="mybooking-details-card">
                    {/* <div className="k-card right-section k-card-vertical">
                      <Button
                        fillMode="link"
                        onClick={(e) => setCouponDialog(true)}
                      >
                        <span className="back-text">Coupon Apply</span>
                        <Icon
                          name="arrow-chevron-right"
                          className="kendo-back-icon"
                        />
                      </Button>
                    </div> */}
                    <div className="booking-history-container">
                      <div className="left-section-removed">
                        <div className="space-details-header">
                          <img
                            src={
                              selectImage !== ""
                                ? `${imageURL}${selectImage}`
                                : ""
                            }
                            className="space-image"
                          />
                          <div className="space-details">
                            <h3>{selectedSpaces}</h3>
                            {/* <p>
                              {" "}
                              {Number(selectedSeats) === 1
                                ? `${selectedSeats} Seat`
                                : `${selectedSeats} Seats`}
                            </p> */}
                          </div>
                          <div style={{ height: "100px" }}></div>
                        </div>{" "}
                        <hr
                          style={{
                            borderTop: "1px solid lightgrey",
                            width: "100%",
                          }}
                        ></hr>
                        <div>
                          <div className="space-info">
                            <div className="info-item">
                              <div className="ul-list">
                                <li>
                                  <h4>Dates</h4>
                                </li>
                                {selectedDurationTypeName === "Hourly" && (
                                  <li>
                                    <div>
                                      {headerFormatDate(new Date(startDate))}
                                    </div>
                                  </li>
                                )}
                                {selectedDurationTypeName !== "Hourly" && (
                                  <li>
                                    <div>
                                      {startDate} - {endDate}
                                    </div>
                                  </li>
                                )}
                              </div>
                              <div
                                onClick={() => {
                                  window.setTimeout(() => {
                                    window.scroll({
                                      top: 950,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }, 5);
                                }}
                              >
                                <span
                                  className="k-icon k-font-icon k-i-edit edit-link"
                                  style={{ paddingRight: " 15px" }}
                                ></span>
                                <span
                                  className="edit-link"
                                  style={{ textDecoration: "underline" }}
                                  onClick={() => {
                                    window.setTimeout(() => {
                                      window.scroll({
                                        top: 950,
                                        left: 0,
                                        behavior: "smooth",
                                      });
                                    }, 5);
                                  }}
                                >
                                  EDIT
                                </span>
                              </div>
                            </div>
                            <div className="info-item">
                              <div className="ul-list">
                                <li>
                                  <h4>Duration</h4>
                                </li>
                                {selectedDurationTypeName === "Monthly" && (
                                  <li>
                                    <div>
                                      {selectedDurationName} *{" "}
                                      {selectedMonthCount}
                                    </div>
                                  </li>
                                )}
                                <li>
                                  {selectedDurationTypeName !== "Monthly" && (
                                    <div>{selectedDurationName}</div>
                                  )}
                                </li>
                              </div>
                              <div
                                onClick={() => {
                                  window.setTimeout(() => {
                                    window.scroll({
                                      top: 950,
                                      left: 0,
                                      behavior: "smooth",
                                    });
                                  }, 5);
                                }}
                              >
                                <span
                                  className="k-icon k-font-icon k-i-edit edit-link"
                                  style={{ paddingRight: " 15px" }}
                                ></span>
                                <span
                                  className="edit-link"
                                  style={{ textDecoration: "underline" }}
                                >
                                  EDIT
                                </span>
                              </div>
                            </div>
                            {selectedDurationTypeName === "Hourly" &&
                            startTimeDilog !== "" ? (
                              <div className="info-item">
                                <div className="ul-list">
                                  <li>
                                    <h4>Start Time</h4>
                                  </li>
                                  <li>
                                    <div>
                                      {getHours12Format(startTimeDilog)}
                                    </div>
                                  </li>
                                </div>
                                <div
                                  onClick={() => {
                                    // getHourlyListDetails(checkInDate);
                                    setPickDuration(true);
                                  }}
                                >
                                  <span
                                    className="k-icon k-font-icon k-i-edit edit-link"
                                    style={{ paddingRight: " 15px" }}
                                  ></span>

                                  <span
                                    className="edit-link"
                                    style={{ textDecoration: "underline" }}
                                    onClick={() => {
                                      // getHourlyListDetails(checkInDate);
                                      setPickDuration(true);
                                    }}
                                  >
                                    EDIT
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div></div>
                            )}
                            {selectedDurationTypeName === "Hourly" &&
                              endTimeDilog !== "" && (
                                <div className="info-item">
                                  <div className="ul-list">
                                    <li>
                                      <h4>End Time</h4>
                                    </li>
                                    <li>
                                      <div>
                                        {getHours12Format(endTimeDilog)}
                                      </div>
                                    </li>
                                  </div>
                                  <div
                                    onClick={() => {
                                      // getHourlyListDetails(checkInDate);
                                      setPickDuration(true);
                                    }}
                                  >
                                    <span
                                      className="k-icon k-font-icon k-i-edit edit-link"
                                      style={{ paddingRight: " 15px" }}
                                    ></span>

                                    <span
                                      className="edit-link"
                                      style={{ textDecoration: "underline" }}
                                      onClick={() => {
                                        // getHourlyListDetails(checkInDate);
                                        setPickDuration(true);
                                      }}
                                    >
                                      EDIT
                                    </span>
                                  </div>
                                </div>
                              )}

                            {/* {selectedSeatsCount !== defaultSeatCountValue && ( */}
                            {isCheckSpaceCount === false && (
                              <div className="info-item">
                                <div className="ul-list">
                                  <li>
                                    <h4>Seats</h4>
                                  </li>
                                  <li>
                                    <div>
                                      {Number(selectedSeatsCount) === 1
                                        ? `${selectedSeatsCount} Seat`
                                        : `${selectedSeatsCount} Seats`}
                                    </div>
                                  </li>
                                </div>
                                <div onClick={handleEdit}>
                                  <span
                                    className="k-icon k-font-icon k-i-edit edit-link"
                                    style={{ paddingRight: " 15px" }}
                                  ></span>
                                  <span
                                    className="edit-link"
                                    style={{ textDecoration: "underline" }}
                                  >
                                    EDIT
                                  </span>
                                </div>
                              </div>
                            )}
                            <>
                              <div></div>
                              {inclusionPackageDetails !== "" &&
                                inclusionPackageDetails !== null &&
                                exclusionPackageDetails !== "" &&
                                exclusionPackageDetails !== null && (
                                  <div className="low-bottom-padding-she">
                                    <Label className="description-text">
                                      Package Details
                                    </Label>
                                    {inclusionPackageDetails !== "" &&
                                      inclusionPackageDetails !== null && (
                                        <p>{`Inclusion : ${inclusionPackageDetails}`}</p>
                                      )}
                                    {exclusionPackageDetails !== "" &&
                                      exclusionPackageDetails !== null && (
                                        <p>{`Exclusion : ${exclusionPackageDetails}`}</p>
                                      )}
                                  </div>
                                )}
                            </>
                          </div>
                        </div>
                      </div>

                      <div className="summary-container-1">
                        <div className="right-section-coupon">
                          {discountList.length !== 0 ? (
                            discountAmount === 0 && (
                              <div>
                                <div className="coupon-container">
                                  <div
                                    className="input-section"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="input-container">
                                      <Input
                                        type="text"
                                        placeholder="Enter Coupon code"
                                        className="personal-fields"
                                        name="coupon"
                                        autoComplete="off"
                                        value={inputValue}
                                        maxLength={25}
                                        onChange={handleInputChangeApply}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" &&
                                            inputValue.trim() !== ""
                                          ) {
                                            handleApplyClick();
                                          }
                                        }}
                                      />

                                      {showApplyButton && (
                                        <Button
                                          fillMode="link"
                                          onClick={applyManualCoupon}
                                        >
                                          <span style={{ color: "#42b677" }}>
                                            Apply
                                          </span>
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="button-section">
                                    <Button
                                      fillMode="link"
                                      onClick={() => setCouponDialog(true)}
                                    >
                                      <span className="apply-coupon-text">
                                        Apply Coupon
                                      </span>
                                    </Button>
                                  </div>
                                </div>
                                {errorInputValue && (
                                  <Label className="error-field">
                                    {errorInputValue}
                                  </Label>
                                )}
                              </div>
                            )
                          ) : (
                            <div>
                              {discountAmount === 0 && (
                                <div className="coupon-container">
                                  <div
                                    className="input-section"
                                    style={{ width: "50%" }}
                                  >
                                    <div className="input-container">
                                      <Input
                                        type="text"
                                        placeholder="Enter Coupon code"
                                        className="personal-fields"
                                        name="coupon"
                                        autoComplete="off"
                                        value={inputValue}
                                        maxLength={25}
                                        onChange={handleInputChangeApply}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" &&
                                            inputValue.trim() !== ""
                                          ) {
                                            handleApplyClick();
                                          }
                                        }}
                                      />

                                      {showApplyButton && (
                                        <Button
                                          fillMode="link"
                                          onClick={applyManualCoupon}
                                        >
                                          <span style={{ color: "#42b677" }}>
                                            Apply
                                          </span>
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  <div className="button-section">
                                    <Label className="manditory-fields-coupon">
                                      No Coupon found
                                    </Label>
                                  </div>
                                </div>
                              )}
                              {errorInputValue && (
                                <Label className="error-field">
                                  {errorInputValue}
                                </Label>
                              )}
                            </div>
                          )}

                          {discountAmount !== 0 && (
                            <>
                              <div className="coupon-applied-text">
                                <Label>Coupon Applied</Label>

                                <Button
                                  fillMode="link"
                                  onClick={(e) => handleRemoveDiscount()}
                                >
                                  <span className="manditory-fields-coupon">
                                    Remove Coupon
                                  </span>
                                </Button>
                              </div>
                              <div className="coupon-applied-text-1">
                                <Label>{discountName}</Label>
                              </div>
                              <div className="coupon-applied-text-2">
                                <Label>{`Coupon - ${discountAmount}%`}</Label>
                              </div>
                            </>
                          )}
                        </div>
                        <Card className="right-section">
                          <div className="bill-details">
                            <div className="summary-container">
                              <div className="summary-item">
                                <span className="field-label total-color">
                                  Deposit
                                </span>
                                <span className="field-text">
                                  ₹ {Math.round(depositAmount)}
                                </span>
                              </div>
                              <div className="summary-dotted-line" />
                              <div className="summary-item">
                                <span className="field-label total-color">
                                  Space Rent
                                </span>
                                <span className="field-text">
                                  ₹ {calculateBaseAmount(bookingTotal)}
                                  {/* {(
                  bookingTotal -
                  (bookingTotal - calculateBaseAmount(bookingTotal))
                ).toFixed(2)} */}
                                  {/* ₹ {calculateBaseAmount(bookingTotal).toFixed(2)} */}
                                  {/* subTotalIncrease */}
                                </span>
                              </div>
                              {discountAmount !== 0 && (
                                <div>
                                  <div className="summary-dotted-line" />
                                  <div className="summary-item">
                                    <span className="field-label total-color">
                                      Discount{" "}
                                      {`(${Math.round(discountAmount)}%)`}
                                      {/* {discountTypeId === 1
                                        ? "(Fixed amount)"
                                        : "(Percentage amount)"} */}
                                    </span>
                                    <span className="field-text">
                                      ₹{" -"}
                                      {discountTypeId === 1
                                        ? discountFixedCalculation()
                                        : discountPercentageCalculation()}
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="summary-dotted-line" />
                              <div className="summary-item">
                                <span className="field-label total-color">
                                  Taxes ({originalTax + "%"})
                                </span>
                                <span className="field-text">
                                  ₹{" "}
                                  {discountTypeId === 1
                                    ? taxFixedCalculation()
                                    : taxPercentageCalculation()}
                                </span>
                              </div>

                              <div className="summary-dotted-line" />
                              <div className="summary-item total">
                                <span className="summary-text">To Pay</span>
                                <span className="summary-price">
                                  ₹{" "}
                                  {discountTypeId === 1
                                    ? totalFixedCalculation()
                                    : totalPercentageCalculation()}
                                </span>
                              </div>
                            </div>
                            <div>
                              <Label className="cancel-heading">
                                Cancellation and Refunds Policy
                              </Label>
                            </div>
                            <div className="total-color">
                              <ul>
                                <p className="policy_title">
                                  1. Cancellation by Members:{" "}
                                </p>
                                <p>
                                  You may cancel your membership at any time by
                                  providing written notice to us via email or
                                  through your account on the Site. Your
                                  membership will be terminated at the end of
                                  your current billing cycle, and you will not
                                  be charged for subsequent billing cycles.
                                </p>
                                <p className="policy_title">
                                  2. Refund Policy:{" "}
                                </p>
                                <p>
                                  If you cancel any of your membership for any
                                  of our spaces, all refunds will be processed
                                  within 7 working days after receipt of all
                                  documentation.
                                </p>
                                <p className="policy_title">
                                  3. No Refunds for Services Rendered
                                </p>
                                <p>
                                  We do not provide refunds for services that
                                  have already been rendered. Any partial use of
                                  a membership plan will be billed in full, and
                                  no prorated refunds will be provided.
                                </p>
                                <p className="policy_title">
                                  4. Cancellation by Us
                                </p>
                                <p>
                                  We reserve the right to cancel your membership
                                  or access to our services at any time if you
                                  violate these Terms. In such cases, no refund
                                  will be provided.{" "}
                                </p>
                              </ul>
                            </div>
                            <div className="total-color space-top-only">
                              <Label className="manditory-fields-coupon">
                                Important: Kindly carry your KYC document
                                containing the provided address for verification
                                purposes.
                              </Label>
                            </div>
                            <div className="button-view">
                              <Button
                                className="button-style"
                                onClick={(e) => savePersonalDetails(e)}
                              >
                                Confirm Booking
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {/* Login dialog */}
            <div>
              {visibleDialog && (
                <Dialog
                  onClose={() => setVisibleDialog(false)}
                  className="dialog-container"
                >
                  <div className="dialog-content">
                    <div className="header-container">
                      <Label className="dialog-heading">Log in</Label>
                      <Button
                        className="svg-aligh"
                        svgIcon={xIcon}
                        onClick={closeDialog}
                      ></Button>
                    </div>
                    <div className="input-group">
                      <Label className="field-label">Email Address</Label>
                      <Label className="manditory-fields">*</Label>
                      <Input
                        type="text"
                        placeholder="Enter Email Address"
                        className="personal-fields"
                        name="bookingUserName"
                        autoComplete="off"
                        value={roomBookLoginFormData?.bookingUserName}
                        maxLength={50}
                        onChange={handleLoginInputChange}
                        onKeyDown={handleKeyDown}
                        required={
                          roomBookLoginFormData?.bookingUserName === "" &&
                          isCheckLoginRequired?.userNameErr === true
                        }
                      />
                      {roomBookLoginFormData?.bookingUserName.trim() === "" &&
                        loginRoomBookErrorFormData?.userNameErr && (
                          <Label className="error-field">
                            {loginRoomBookErrorFormData?.userNameErr}
                          </Label>
                        )}
                      {roomBookLoginFormData?.bookingUserName.trim() !== "" &&
                        !emailRegex.test(
                          roomBookLoginFormData.bookingUserName
                        ) &&
                        loginRoomBookErrorFormData?.userNameErr && (
                          <Label className="error-field">
                            {loginRoomBookErrorFormData?.userNameErr}
                          </Label>
                        )}
                    </div>

                    <div className="input-group">
                      <Label className="field-label">Password</Label>
                      <Label className="manditory-fields">*</Label>
                      <Input
                        type="password"
                        placeholder="Enter Password"
                        name="bookingUserPassword"
                        className="field-top personal-fields"
                        autoComplete="off"
                        value={roomBookLoginFormData?.bookingUserPassword}
                        valid={passwordError === ""}
                        onChange={handleLoginInputChange}
                        onKeyDown={handleKeyDown}
                        required={
                          roomBookLoginFormData?.bookingUserPassword === "" &&
                          isCheckLoginRequired?.userPasswordErrr === true
                        }
                        minLength={6}
                        maxLength={18}
                      />
                      {roomBookLoginFormData?.bookingUserPassword.trim() ===
                        "" &&
                        loginRoomBookErrorFormData?.userPasswordErrr && (
                          <Label className="error-field">
                            {loginRoomBookErrorFormData?.userPasswordErrr}
                          </Label>
                        )}
                    </div>
                    {/* Forgot Password Link */}
                    <div style={{ marginTop: "10px" }}>
                      <a
                        href="#"
                        onClick={() => setForgotPasswordDialogOpen(true)}
                        style={{ color: "#007BFF", cursor: "pointer" }}
                      >
                        Forgot Password?
                      </a>
                    </div>

                    <Button
                      type={"submit"}
                      className="login-button-style"
                      onClick={loginAPI} // Trigger login on button click
                    >
                      Login
                    </Button>

                    <div
                      className="link-register"
                      onClick={() => {
                        resetLoginDatas();
                        setVisibleDialog(false);
                        setVisibleDetails(true);
                      }}
                    >
                      REGISTER
                    </div>
                  </div>
                </Dialog>
              )}
            </div>
            {/* Forget Password Dialogs */}

            {/* Forgot Password Dialog */}
            {forgotPasswordDialogOpen && (
              <Dialog
                onClose={handleForgotPasswordDialogClose}
                // className="dialog-container"
                className="dialog-container fixed-height-dialog"
              >
                <div className="dialog-content">
                  <div className="header-container">
                    <Label className="dialog-heading">Forgot Password</Label>
                    <Button
                      className="svg-aligh"
                      svgIcon={xIcon}
                      onClick={handleForgotPasswordDialogClose}
                    ></Button>
                  </div>
                  <div className="input-group">
                    <Label className="field-label">Email Address</Label>
                    <Input
                      type="text"
                      placeholder="Enter your Email Address"
                      value={forgotPasswordEmail}
                      onChange={handleForgotPasswordEmailChange}
                    />
                    {forgotPasswordError && (
                      <Label className="error-field">
                        {forgotPasswordError}
                      </Label>
                    )}
                  </div>
                  <Button
                    onClick={handleForgotPassword}
                    className="login-button-style"
                    style={{ marginTop: "15px" }}
                  >
                    Continue
                  </Button>
                </div>
              </Dialog>
            )}

            {/* Success Dialog after Forgot Password */}
            {forgotPasswordSuccessDialogOpen && (
              <Dialog
                onClose={() => setForgotPasswordSuccessDialogOpen(false)}
                className="dialog-container fixed-height-dialog"
              >
                <div className="dialog-content">
                  <div className="header-container">
                    <Label className="dialog-heading">Success</Label>
                    <Button
                      className="svg-aligh"
                      svgIcon={xIcon}
                      onClick={() => setForgotPasswordSuccessDialogOpen(false)}
                    ></Button>
                  </div>
                  <h5>
                    The recovery password has been sent to your email address.
                  </h5>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginTop: "15px",
                    }}
                    onClick={() => setForgotPasswordSuccessDialogOpen(false)}
                  >
                    OK
                  </Button>
                </div>
              </Dialog>
            )}
            {/* Register dialog */}
            <div>
              {visibleDetails && (
                <Dialog
                  onClose={() => setVisibleDetails(false)}
                  className="dialog-container"
                >
                  <div style={{ height: "550px", paddingBottom: "30px" }}>
                    <div className="header-container">
                      <Label className="dialog-heading">
                        Create an account
                      </Label>
                      <Button
                        className="svg-aligh"
                        svgIcon={xIcon}
                        onClick={closeDialog}
                      ></Button>
                    </div>
                    <div className="personal-details">
                      <div className="initial-field">
                        <Label className="field-label">Booking for ?</Label>
                        <Label className="manditory-fields">*</Label>
                        <br />
                        <div className="radio-group field-top">
                          <RadioButton
                            name="customerType"
                            value="Company"
                            checked={selectedValue === "Company"}
                            label="Company"
                            onChange={handleChange}
                          />
                          <div className="space" />
                          <RadioButton
                            name="customerType"
                            value="Individual"
                            checked={selectedValue === "Individual"}
                            label="Individual"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="detail-content">
                        {selectedValue === "Company" && (
                          <div className="remaining-field">
                            <Label className="field-label">Company Name</Label>
                            <Label className="manditory-fields">*</Label>
                            <Input
                              type="text"
                              placeholder="Enter Company Name"
                              className="personal-fields"
                              name="CompanyName"
                              autoComplete="off"
                              value={roomBookFormData?.CompanyName}
                              maxLength={100}
                              onChange={handleInputChange}
                              required={
                                roomBookFormData?.CompanyName.trim() === "" &&
                                true &&
                                isCheckRegisterRequired?.companyNameErr === true
                              }
                            />
                            <div>
                              {roomBookFormData?.CompanyName.trim() === "" &&
                                registerRoomBookErrorFormData?.companyNameErr && (
                                  <Label className="error-field">
                                    {
                                      registerRoomBookErrorFormData?.companyNameErr
                                    }
                                  </Label>
                                )}
                            </div>
                          </div>
                        )}
                        <div className="remaining-field">
                          <Label className="field-label">
                            {currentName} First Name
                          </Label>
                          <Label className="manditory-fields">*</Label>
                          <Input
                            type="text"
                            placeholder="Enter First Name"
                            className="personal-fields"
                            name="customerName"
                            autoComplete="off"
                            value={roomBookFormData?.customerName}
                            maxLength={50}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.customerName === "" &&
                              true &&
                              isCheckRegisterRequired?.companyNameErr === true
                            }
                          />
                          <div>
                            {roomBookFormData?.customerName.trim() === "" &&
                              registerRoomBookErrorFormData?.customerNameErr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerNameErr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">
                            {currentName} Last Name
                          </Label>
                          <Input
                            type="text"
                            placeholder="Enter Last Name"
                            className="personal-fields"
                            name="customerLastName"
                            autoComplete="off"
                            value={roomBookFormData?.customerLastName}
                            maxLength={50}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">Mobile Number</Label>
                          <Label className="manditory-fields">*</Label>
                          <Input
                            placeholder="Enter Mobile Number"
                            className="field-top personal-fields"
                            name="contactNo"
                            autoComplete="off"
                            value={roomBookFormData?.contactNo}
                            maxLength={10}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.contactNo === "" &&
                              true &&
                              isCheckRegisterRequired?.customerMobileErrr ===
                                true
                            }
                          />
                          <div>
                            {!roomBookFormData?.contactNo.trim() &&
                              registerRoomBookErrorFormData?.customerMobileErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerMobileErrr
                                  }
                                </Label>
                              )}
                            {roomBookFormData?.contactNo.trim() !== "" &&
                              (roomBookFormData?.contactNo.length < 10 ||
                                !mobileRegex.test(
                                  roomBookFormData.contactNo
                                )) &&
                              registerRoomBookErrorFormData?.customerMobileErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerMobileErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">Email Address</Label>
                          <Label className="manditory-fields">*</Label>
                          <Input
                            className="field-top personal-fields"
                            placeholder="Enter Email Address"
                            name="email"
                            autoComplete="off"
                            value={roomBookFormData?.email}
                            maxLength={50}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.email === "" &&
                              true &&
                              isCheckRegisterRequired?.customerEmailErrr ===
                                true
                            }
                          />
                          <div>
                            {roomBookFormData?.email.trim() === "" &&
                              registerRoomBookErrorFormData?.customerEmailErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerEmailErrr
                                  }
                                </Label>
                              )}
                            {roomBookFormData?.email.trim() !== "" &&
                              !emailRegex.test(roomBookFormData.email) &&
                              registerRoomBookErrorFormData?.customerEmailErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerEmailErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">
                            {currentAddress} Address 1
                          </Label>
                          <Label className="manditory-fields">*</Label>
                          <TextArea
                            placeholder="Enter Address 1"
                            // name="address1"
                            name="address"
                            autoComplete="off"
                            value={roomBookFormData?.address}
                            maxLength={100}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.address === "" &&
                              true &&
                              isCheckRegisterRequired?.customerAddres1Eerrr ===
                                true
                            }
                          />
                          <div>
                            <Label className="error-field">
                              Kindly ensure that the address matches exactly as
                              stated in the KYC document
                            </Label>
                          </div>
                          <div>
                            {roomBookFormData?.address.trim() === "" &&
                              registerRoomBookErrorFormData?.customerAddres1Eerrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerAddres1Eerrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">
                            {currentAddress} Address 2
                          </Label>
                          <Label className="manditory-fields">*</Label>
                          <TextArea
                            placeholder="Enter Address 2"
                            name="address2"
                            autoComplete="off"
                            value={roomBookFormData?.address2}
                            maxLength={100}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.address2 === "" &&
                              true &&
                              isCheckRegisterRequired?.customerAddres2Eerrr ===
                                true
                            }
                          />
                          <div>
                            <Label className="error-field">
                              Kindly ensure that the address matches exactly as
                              stated in the KYC document
                            </Label>
                          </div>
                          <div>
                            {roomBookFormData?.address2.trim() === "" &&
                              registerRoomBookErrorFormData?.customerAddres2Eerrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerAddres2Eerrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">Country</Label>
                          <Label className="manditory-fields">*</Label>
                          <DropDownList
                            className="personal-fields"
                            textField="countryName"
                            dataItemKey="id"
                            name="countryName"
                            data={countryData}
                            value={selectedCountry}
                            onChange={handleDropdownChange}
                          />
                          {/* <Input
                            type="text"
                            placeholder="Enter Country"
                            className="personal-fields"
                            name="country"
                            autoComplete="off"
                            value={roomBookFormData?.country!}
                            maxLength={50}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.country === "" &&
                              true &&
                              isCheckRegisterRequired?.customerCountryErrr ===
                                true
                            }
                          /> */}
                          <div>
                            {roomBookFormData?.country.trim() === "" &&
                              registerRoomBookErrorFormData?.customerCountryErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerCountryErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">State</Label>
                          <Label className="manditory-fields">*</Label>
                          <DropDownList
                            className="personal-fields"
                            textField="stateName"
                            dataItemKey="id"
                            name="stateName"
                            disabled={selectedCountryId === 0 ? true : false}
                            data={stateData}
                            value={selectedState}
                            onChange={handleDropdownChange}
                          />
                          {/* <Input
                            type="text"
                            placeholder="Enter State"
                            className="personal-fields"
                            name="state"
                            autoComplete="off"
                            value={roomBookFormData?.state!}
                            maxLength={50}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.state === "" &&
                              true &&
                              isCheckRegisterRequired?.customerStateErrr ===
                                true
                            }
                          /> */}
                          <div>
                            {roomBookFormData?.state.trim() === "" &&
                              registerRoomBookErrorFormData?.customerStateErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerStateErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">City</Label>
                          <Label className="manditory-fields">*</Label>
                          <DropDownList
                            className="personal-fields"
                            textField="cityName"
                            dataItemKey="id"
                            name="cityName"
                            disabled={selectedStateId === 0 ? true : false}
                            data={cityData}
                            value={selectedCity}
                            onChange={handleDropdownChange}
                          />
                          {/* <Input
                            type="text"
                            placeholder="Enter City"
                            className="personal-fields"
                            name="city"
                            autoComplete="off"
                            value={roomBookFormData?.city!}
                            maxLength={50}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.city === "" &&
                              true &&
                              isCheckRegisterRequired?.customerCityErrr === true
                            }
                          /> */}
                          <div>
                            {roomBookFormData?.city.trim() === "" &&
                              registerRoomBookErrorFormData?.customerCityErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerCityErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">Pincode</Label>
                          <Label className="manditory-fields">*</Label>
                          <Input
                            placeholder="Enter Pincode"
                            className="personal-fields"
                            name="pincode"
                            autoComplete="off"
                            value={roomBookFormData?.pincode!}
                            maxLength={6}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.pincode === "" &&
                              true &&
                              isCheckRegisterRequired?.customerPincodeErrr ===
                                true
                            }
                          />
                          <div>
                            {roomBookFormData?.pincode.trim() === "" &&
                              registerRoomBookErrorFormData?.customerPincodeErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerPincodeErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">KYC Document</Label>
                          <Label className="manditory-fields">*</Label>
                          <DropDownList
                            className="personal-fields"
                            textField="Name"
                            dataItemKey="Id"
                            name="Name"
                            data={kycDetailsData}
                            value={selectedKYC}
                            onChange={handleDropdownChange}
                          />
                          <div>
                            {(selectedKYC.Name === "Select KYC Document" ||
                              selectedKYCName === "Select KYC Document") &&
                              registerRoomBookErrorFormData?.customerKYCFileErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerKYCFileErrr
                                  }
                                </Label>
                              )}
                          </div>
                          <ExternalDropZone
                            className="field-space border-file-upload"
                            uploadRef={uploadRefs}
                            customHint={hint}
                            customNote={note}
                          />
                          <Upload
                            ref={uploadRefs}
                            restrictions={{
                              maxFileSize: 2000000,
                              allowedExtensions: [
                                ".jpeg",
                                ".png",
                                ".jpg",
                                ".pdf",
                              ],
                            }}
                            batch={false}
                            multiple={false}
                            defaultFiles={[]}
                            withCredentials={false}
                            saveUrl={onSaveRequest}
                            removeUrl={onRemoveRequest}
                          />
                          <div>
                            {imageData === "" &&
                              registerRoomBookErrorFormData?.customerKYCDocumentErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerKYCDocumentErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                        {selectedKYCName !== "" && (
                          <div className="remaining-field">
                            <Label className="field-label">
                              KYC Document Number
                            </Label>
                            <Label className="manditory-fields">*</Label>
                            <Input
                              placeholder={`Enter ${selectedKYCName} Number`}
                              className="field-top personal-fields"
                              name="kycNumber"
                              autoComplete="off"
                              value={roomBookFormData?.kycNumber}
                              maxLength={
                                selectedKYCID === 1
                                  ? 12
                                  : selectedKYCID === 2
                                  ? 10
                                  : selectedKYCID === 3
                                  ? 15
                                  : selectedKYCID === 4
                                  ? 15
                                  : selectedKYCID === 5
                                  ? 21
                                  : 0 // Default fallback value if none match
                              }
                              onChange={handleInputChange}
                              required={
                                roomBookFormData?.kycNumber === "" &&
                                true &&
                                isCheckRegisterRequired?.customerKycNumberErrr ===
                                  true
                              }
                            />
                            <div>
                              {!roomBookFormData?.kycNumber.trim() &&
                                registerRoomBookErrorFormData?.customerKycNumberErrr && (
                                  <Label className="error-field">
                                    {
                                      registerRoomBookErrorFormData?.customerKycNumberErrr
                                    }
                                  </Label>
                                )}
                              {roomBookFormData?.kycNumber.trim() !== "" &&
                                registerRoomBookErrorFormData?.customerKycNumberErrr && (
                                  <Label className="error-field">
                                    {
                                      registerRoomBookErrorFormData?.customerKycNumberErrr
                                    }
                                  </Label>
                                )}
                            </div>
                          </div>
                        )}
                        {/* <div className="remaining-field">
                          <Label className="field-label">GST Number</Label>
                          <Input
                            className="field-top personal-fields"
                            placeholder="Enter GST Number"
                            name="gst"
                            autoComplete="off"
                            maxLength={15}
                            value={roomBookFormData?.gst}
                            onChange={handleInputChange}
                          />
                        </div> */}
                        <div className="remaining-field">
                          <Label className="field-label">Password</Label>
                          <Label className="manditory-fields">*</Label>

                          <Input
                            className="field-top personal-fields"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            name="password"
                            autoComplete="off"
                            value={roomBookFormData?.password}
                            onChange={handleInputChange}
                            required={
                              roomBookFormData?.password === "" &&
                              true &&
                              isCheckRegisterRequired?.customerPassowordErrr ===
                                true
                            }
                            minLength={8}
                            maxLength={18}
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "55px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? (
                              <SvgIcon icon={eyeIcon} />
                            ) : (
                              <SvgIcon icon={eyeSlashIcon} />
                            )}
                          </span>
                          <div>
                            {roomBookFormData?.password.trim() === "" ||
                              (passwordValidationError !== "" &&
                                registerRoomBookErrorFormData?.customerPasswordErrr && (
                                  <Label className="error-field">
                                    {
                                      registerRoomBookErrorFormData?.customerPasswordErrr
                                    }
                                  </Label>
                                ))}
                          </div>
                        </div>
                        <div className="remaining-field">
                          <Label className="field-label">
                            Confirm Password
                          </Label>
                          <Label className="manditory-fields">*</Label>
                          <Input
                            className="field-top personal-fields"
                            type={showConfirmPassword ? "text" : "password"}
                            autoComplete="off"
                            value={confirmPassword}
                            onChange={handleInputChange}
                            onKeyDown={handleKeyDownRegister}
                            name="confirmPassword"
                            placeholder="Enter Confirm password"
                            valid={
                              roomBookFormData?.password === confirmPassword
                            }
                            required={
                              roomBookFormData?.password !== confirmPassword &&
                              true &&
                              isCheckRegisterRequired?.customerConfirmPasswordErrr ===
                                true
                            }
                            minLength={8}
                            maxLength={18}
                          />
                          <span
                            onClick={toggleConfirmPasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "55px",
                              marginTop: "10px",
                              cursor: "pointer",
                            }}
                          >
                            {showConfirmPassword ? (
                              <SvgIcon icon={eyeIcon} />
                            ) : (
                              <SvgIcon icon={eyeSlashIcon} />
                            )}
                          </span>
                          <div>
                            {roomBookFormData?.password !== confirmPassword &&
                              registerRoomBookErrorFormData?.customerConfirmPasswordErrr && (
                                <Label className="error-field">
                                  {
                                    registerRoomBookErrorFormData?.customerConfirmPasswordErrr
                                  }
                                </Label>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="remaining-field button-view">
                      <Button
                        type={"submit"}
                        className="button-style"
                        onClick={(e) => {
                          registerUserAPI(e);
                        }}
                      >
                        Register
                      </Button>
                    </div>
                    <div
                      className="link-register"
                      onClick={() => {
                        resetRegisterDatas();
                        setVisibleDialog(true);
                        setVisibleDetails(false);
                      }}
                    >
                      LOGIN
                    </div>
                    <div style={{ paddingBottom: "30px" }}></div>
                  </div>
                </Dialog>
              )}
            </div>
            {/* Alert dialog */}
            <div>
              {visible && (
                <Dialog
                  title={"Message"}
                  onClose={toggleDialog}
                  style={{ alignItems: "center" }}
                >
                  <Label
                    style={{
                      height: "80px",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {alertContent}
                    <div
                      style={{
                        padding: "20px",
                      }}
                    />
                    <Button
                      className="alert-button"
                      type="button"
                      onClick={toggleDialog}
                    >
                      Ok
                    </Button>
                  </Label>
                </Dialog>
              )}
            </div>
            {/* Duration dialog */}
            {showDialog && (
              <Dialog
                onClose={handleHidePopup}
                title="All Duration Types"
                style={{ alignItems: "center" }}
              >
                <div>
                  {popupSpaceDetails!.map((space, index) => (
                    <p key={index}>
                      {space!.Duration.DurationType}: ₹{space!.RentAmount}
                    </p>
                  ))}
                </div>
              </Dialog>
            )}
            {/* Discount dialog */}
            <div>
              {couponDialog && (
                <Dialog
                  onClose={() => setCouponDialog(false)}
                  className="dialog-container"
                >
                  <div style={{ paddingBottom: "30px" }}>
                    <div className="header-container">
                      <Label className="dialog-heading">Apply Coupon</Label>
                      <Button
                        className="svg-align"
                        svgIcon={xIcon}
                        onClick={() => handleRemoveDiscount()}
                      />
                    </div>

                    <div className="personal-details">
                      <div className="initial-field">
                        <br />
                        {discountList!.map((coupon, index) => (
                          <div
                            key={index}
                            className={
                              selectedIndex === index
                                ? "boxshadow-enable discount-container"
                                : "boxshadow-disable discount-container"
                            }
                            style={{
                              width: "300px",
                              backgroundColor: "#f8f8f8",
                              borderRadius: "10px",
                              margin: "10px",
                              cursor: "pointer",
                              border:
                                selectedIndex === index
                                  ? "2px solid #42b677"
                                  : "1px solid transparent",
                            }}
                            onClick={() => setSelectedIndex(index)}
                          >
                            <Label className="discount-padding discount-coupan-text">
                              {coupon!["discountName"]}
                            </Label>
                            <Label className="discount-padding">
                              {coupon!["discountDescription"]}
                            </Label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="remaining-field button-view">
                      <Button
                        disabled={selectedIndex !== null ? false : true}
                        className="button-style"
                        onClick={() => handleDiscountSelect()}
                      >
                        Apply
                      </Button>
                    </div>
                    <div style={{ paddingBottom: "30px" }}></div>
                  </div>
                </Dialog>
              )}

              {isOtpDialogOpen && (
                <Dialog title="OTP Verification" onClose={handleCloseDialog}>
                  <h5>
                    OTP has been sent to your email address. Please enter the
                    OTP below:
                  </h5>

                  {/* OTP Input fields */}
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {otp.map((digit, index) => (
                      <Input
                        key={index}
                        id={`otp-${index}`}
                        value={digit}
                        maxLength={1}
                        style={{
                          width: "50px",
                          textAlign: "center",
                          fontSize: "25px",
                        }}
                        onChange={(e) => handleOtpChange(index, e.value)}
                        onKeyDown={(e) => handleOtpKeyDown(index, e)}
                      />
                    ))}
                  </div>

                  {/* Error message within dialog */}
                  {otpError && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "17px",
                      }}
                    >
                      {otpError}
                    </div>
                  )}

                  {/* Countdown Timer and Resend OTP Button */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "15px",
                      gap: "10px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "16px",
                        color: timer > 0 ? "gray" : "#42B677",
                      }}
                    >
                      {timer > 0
                        ? `Resend OTP in ${timer}s`
                        : "You can now resend OTP"}
                    </span>
                    <Button
                      style={{
                        fontSize: "16px",
                        backgroundColor: "#42B677",
                        color: "white",
                      }}
                      onClick={handleResendOtp}
                      disabled={!isResendEnabled}
                    >
                      Resend OTP
                    </Button>
                  </div>
                  {resendMessage && (
                    <div
                      style={{
                        color: "#42B677",
                        fontSize: "16px",
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {resendMessage}
                    </div>
                  )}
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginLeft: "35%",
                      marginTop: "15px",
                    }}
                    onClick={() =>
                      handleOtpVerification(roomBookFormData.email)
                    }
                  >
                    Verify OTP
                  </Button>
                </Dialog>
              )}
              {isOtpSuccessDialogOpen && (
                <Dialog
                  title="OTP Verification"
                  onClose={() => setOtpSuccessDialogOpen(false)}
                >
                  <h5>
                    Your account has been verified. Please Login to continue
                  </h5>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginLeft: "35%",
                      marginTop: "15px",
                    }}
                    onClick={() => handleOtpSuccessAlertClose()}
                  >
                    OK
                  </Button>
                </Dialog>
              )}

              {/* Returning User verification */}
              {isExistingOtpDialogVerificationOpen && (
                <Dialog
                  title="OTP Verification"
                  onClose={() => handleExistUserAlertClose()}
                >
                  <h5>
                    OTP has been sent to your email address. Please enter the
                    OTP below:
                  </h5>
                  <div className="input-group">
                    <Label className="field-label">Email Address</Label>
                    <Input
                      type="text"
                      placeholder="Enter Email Address"
                      className="personal-fields"
                      name="bookingUserName"
                      autoComplete="off"
                      contentEditable="false"
                      disabled
                      value={roomBookLoginFormData?.bookingUserName}
                    />
                  </div>

                  {/* OTP Input fields */}
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    {otp.map((digit, index) => (
                      <Input
                        key={index}
                        id={`otp-${index}`}
                        value={digit}
                        maxLength={1}
                        style={{
                          width: "50px",
                          textAlign: "center",
                          fontSize: "25px",
                        }}
                        onChange={(e) => handleOtpChange(index, e.value)}
                        onKeyDown={(e) => handleOtpKeyDown(index, e)}
                      />
                    ))}
                  </div>

                  {/* Error message within dialog */}
                  {otpError && (
                    <div
                      style={{
                        color: "red",
                        marginTop: "10px",
                        fontSize: "17px",
                      }}
                    >
                      {otpError}
                    </div>
                  )}
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginLeft: "35%",
                      marginTop: "15px",
                    }}
                    onClick={() =>
                      handleOtpVerification(
                        roomBookLoginFormData.bookingUserName
                      )
                    }
                  >
                    Verify OTP
                  </Button>
                </Dialog>
              )}
              {isExistingOtpDialogOpen && (
                <Dialog
                  title="OTP Verification"
                  onClose={() => handleExistUserCloseAlert()}
                >
                  <h5>
                    Your account has not been verified. Please select an option
                    to get verified
                  </h5>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginLeft: "35%",
                      marginTop: "15px",
                    }}
                    onClick={() => handleExistUserAlert()}
                  >
                    Already have OTP
                  </Button>
                  <Button
                    style={{
                      color: "white",
                      backgroundColor: "#42B677",
                      fontSize: "20px",
                      width: "30%",
                      marginLeft: "35%",
                      marginTop: "15px",
                    }}
                    onClick={handleExisitingUserResendOtp}
                  >
                    Resend OTP
                  </Button>
                </Dialog>
              )}
            </div>
          </div>
        )}
      </div>
      <>
        <SessionTimeout
          isLoggedIn={loggedIn}
          timerKey={timerKey}
          onSessionTimeout={handleOpenLoginDialog}
        />
      </>
    </>
  );
};
