import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import "./styles.css";
import { BookingForm } from "./RoomBooking";
import { MyBookingsList } from "./MyBookings";
import { BookingDetail } from "./BookingDetail";
import { MyProfile } from "./MyProfile";
import { FoodOrders } from "./FoodOrders";
import { MyOrders } from "./MyOrders";
import SessionTimeout from "./services/SessionTimeout";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login state
  const [timerKey, setTimerKey] = useState(Date.now()); // Key to reset the session timer
  const navigate = useNavigate();

  // Check if user is logged in by inspecting local storage or any login state
  useEffect(() => {
    const userToken = localStorage.getItem("userToken"); // Check for token in localStorage
    setIsLoggedIn(!!userToken); // Set login state based on token presence
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true); // Update login state when user successfully logs in
    localStorage.setItem("userToken", "some-user-token"); // Store token (replace with actual)
    setTimerKey(Date.now()); // Update the timer key to trigger reset for session timeout

    navigate("/"); // Optionally redirect to home or dashboard after login
  };

  return (
    <>
      <SessionTimeout isLoggedIn={isLoggedIn} timerKey={timerKey} />
      {/* This component will handle session timeout and display the popup */}
      <div>
        <Routes>
          <Route path="/" element={<BookingForm />} />
          <Route path="/bookings" element={<MyBookingsList />} />
          <Route path="/bookings/bookingdetails" element={<BookingDetail />} />
          <Route path="/myprofile" element={<MyProfile />} />
          <Route path="/foodorders" element={<FoodOrders />} />
          <Route path="/myorders" element={<MyOrders />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
